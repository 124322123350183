import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import _ from 'lodash';
import BasicPlan from './basic-plan';
import PaymentOptions from './payment-options';
import {
  StepIndicator,
  SideBar
} from '../../../msreact-kit/packages/html-components';
import Loader from '../../../components/Shared/Utils/Loader';
import loading from '../../../actions/LoaderAction';
import PricingPlanPreview from '../../api/AddAPI/Pricing/PricingPlanPreview';
import {
  createPricingPlan,
  auditLogging,
  updatePricingPlan,
  updatePricingStatus,
  getApiPlans,
  updatePlanGroupNameAttribute,
  updatePrivatePlanConfigAttributes,
  updatePrivatePlanConfigPayload,
  updateDeletedCompanyId,
  addPrivatePlanDetails,
  updatePrivatePlanDetails
} from '../../../actions/api/pricing-plan-action';
import { defaultSlabDetails } from '../../../data/appConstants';
import './index.scss';
import i18next from 'i18next';
import getUserId from '../../../utils/get-user-id';
import idConstants, { PRICING, REGION } from '../../../data/idConstants';
import { GAConstants } from '../../../data/ga-constants';
import { sortByDisplayOrder } from '../../../utils';
import { updateProductStatus } from '../../../actions/api/hardwareAction';
import { DynamicFieldConstants } from '../../../shared/constants';
import {
  getInitialCurrentPlan,
  getValidationFieldsInPaymentOptions
} from '../utils';
import {
  FieldConstants,
  pricingConstants
} from '../../../shared/constants/field-constants';
import {
  initialPlanGroupNameAttribute,
  initialPrivatePlanConfigAttributes,
  initialPrivatePlanConfigPayload
} from './basic-plan/data';

const PricingPlanScreen = ({
  prodId,
  pricingPlanData,
  setShowPricingPlanModal,
  onBoardingFormData,
  apiPlans,
  editPlan
}) => {
  const [planData, setPlanData] = useState({});
  const [planDataCopy, setPlanDataCopy] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [showSideBar, setShowSideBar] = useState(true);
  const [previewData, setPreviewData] = useState({
    netPrice: null,
    currency: '',
    planName: 'Plan Name',
    planDetailedItem: []
  });
  const [priceSlabs, setPriceSlabs] = useState([]);
  const [isPricingSlabsValid, setIsPricingSlabsValid] = useState(true);
  const [pricingPlanPayload, setPricingPlanPayload] = useState({
    currentPlan:
      pricingPlanData.initialValue ?? _.cloneDeep(getInitialCurrentPlan()),
    pricingPlanTypeTabValue: '',
    isDefaultPlan: false,
    priceSlabs: [defaultSlabDetails]
  });
  const [currentTab, setCurrentTab] = useState();
  const [enableInputs, setEnableInputs] = useState({
    error: '',
    name: '',
    value: ''
  });
  const [isEnabled, setIsEnabled] = useState(false);
  const dispatch = useDispatch();

  const {
    loader,
    prodBasicInfo,
    masterData,
    tenants,
    planGroupNameAttribute,
    privatePlanConfigAttributes,
    privatePlanConfigPayload
  } = useSelector((state) => {
    return {
      loader: state.LoaderReducer.loader,
      prodBasicInfo: state.FormReducer.prodBasicInfo,
      masterData: state.MasterDataReducer.pricingPlanMasterData,
      tenants: state.TenantServiceReducer?.tenants,
      planGroupNameAttribute: state.ProductPricingPlanReducer.planGroupNameAttribute,
      privatePlanConfigAttributes:
        state.ProductPricingPlanReducer.privatePlanConfigAttributes,
      privatePlanConfigPayload:
        state.ProductPricingPlanReducer.privatePlanConfigPayload
    };
  });

  const categoryId = prodBasicInfo?.productCategory?.categoryId;
  let allTabs;

  const groupByPaymentMode = (paymentModePref) => {
    const groupedPaymentModePref = paymentModePref.reduce(
      (tempPaymentModePref, item) => {
        const { paymentMode, occurrence } = item;
        const existingPaymentModeIndex = tempPaymentModePref.findIndex(
          (paymentModeItem) => paymentModeItem.paymentMode === paymentMode
        );

        if (Array.isArray(occurrence)) {
          return paymentModePref;
        } else if (existingPaymentModeIndex !== -1) {
          if (
            Array.isArray(tempPaymentModePref[existingPaymentModeIndex].occurrence)
          ) {
            tempPaymentModePref[existingPaymentModeIndex].occurrence.push(
              occurrence
            );
          } else {
            tempPaymentModePref[existingPaymentModeIndex].occurrence = [
              tempPaymentModePref[existingPaymentModeIndex].occurrence,
              occurrence
            ];
          }
        } else {
          tempPaymentModePref.push({
            paymentMode: paymentMode,
            occurrence: [occurrence]
          });
        }
        return tempPaymentModePref;
      },
      []
    );

    return groupedPaymentModePref;
  };

  useEffect(() => {
    masterData?.plan_type?.forEach((planTypeItem) => {
      planTypeItem?.props?.forEach((prop) => {
        let paymentMode = groupByPaymentMode(prop?.paymentModePref);
        // EU and NA region doesn't support EMANDATE flow
        if (
          process.env.REACT_APP_REGION !== REGION.IN.CODE &&
          planTypeItem.name !== idConstants.pricingPlanType.customPlans
        ) {
          paymentMode = paymentMode?.filter(
            (payment) =>
              payment.paymentMode === idConstants.pricingPaymentModePref.invoice
          );
        }
        prop.paymentModePref = paymentMode;
      });
    });
  }, [masterData]);

  useEffect(() => {
    if (editPlan) {
      const {
        planName,
        description,
        planBenefits,
        planCost,
        planBillingCycle,
        rateLimit,
        consumption,
        planId
      } = editPlan;

      const planBefitsData = planBenefits?.map((item) => ({
        id: item.planBenefitId,
        benefitOrder: item.benefitOrder,
        benefitDescription: item.benefitDescription,
        planBenefitId: item.planBenefitId
      }));

      const pricingPlanPayloadCopy = {
        ..._.cloneDeep(pricingPlanPayload),
        currentPlan: {
          ...pricingPlanPayload.currentPlan,
          planName,
          description,
          planBenefits: planBefitsData,
          price: Number(planCost),
          billingCycleType: planBillingCycle?.billingCycleType,
          rateLimitType: rateLimit?.rateLimitType?.limitType,
          chargeableUnit: {
            chargeableUnitType: rateLimit?.chargeableUnit?.chargeableUnitType
          },
          quota: rateLimit?.timeUnit?.timeUnitCode,
          consumption,
          planId,
          planGroupName: editPlan.planGroupName,
          privatePlanDetails: editPlan.privatePlanDetails
        },
        pricingPlanTypeTabValue: editPlan?.billingType?.billingTypeCode
      };
      if (editPlan?.rateLimit?.slabLimitDetails) {
        pricingPlanPayloadCopy.priceSlabs = editPlan.rateLimit.slabLimitDetails;
      }
      setPricingPlanPayload(pricingPlanPayloadCopy);
      setPreviewData((prev) => ({
        ...prev,
        planDetailedItem: planBefitsData
      }));
      handlePrefillValue();
    }
  }, [editPlan, planDataCopy]);

  // Private Plan Config
  useEffect(() => {
    if (
      !editPlan ||
      editPlan?.planGroupName !== PRICING.PLAN_GROUP_TYPE.PRIVATE.VALUE
    )
      return;

    const planGroupNameAttr = _.cloneDeep(planGroupNameAttribute);
    planGroupNameAttr.value = PRICING.PLAN_GROUP_TYPE.PRIVATE.LABEL;
    dispatch(updatePlanGroupNameAttribute(planGroupNameAttr));
    updatePricingPlanPayload('planGroupName', editPlan?.planGroupName, 1);

    const privatePlanConfigAttr = _.cloneDeep(privatePlanConfigAttributes);
    privatePlanConfigAttr.forEach((attr) => {
      if (attr.name === 'tenantId') {
        const tenant = tenants?.find(
          (tenant) => tenant.value === editPlan?.privatePlanDetails?.tenantId
        );
        if (tenant) attr.value = tenant.label;
      }
    });
    dispatch(updatePrivatePlanConfigAttributes(privatePlanConfigAttr));

    const privatePlanConfig = _.cloneDeep(privatePlanConfigPayload);
    privatePlanConfig.tenantId = editPlan?.privatePlanDetails?.tenantId;
    privatePlanConfig.companyId = editPlan?.privatePlanDetails?.companyDetails;
    dispatch(updatePrivatePlanConfigPayload(privatePlanConfig));
  }, [editPlan]);

  useEffect(() => {
    axios.interceptors.request.use(
      function (config) {
        dispatch(loading(true));
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        dispatch(loading(false));
        return response;
      },
      function (error) {
        dispatch(loading(false));
        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    const pricingTabs = sortByDisplayOrder(pricingPlanData?.sections[1]?.tabs);
    let filteredPaymentOptions = [];
    pricingTabs?.forEach((tabItem) => {
      onBoardingFormData?.billingType?.forEach((billingItem) => {
        if (tabItem.name === billingItem.type) {
          filteredPaymentOptions.push(tabItem);
        }
      });
    });
    if (filteredPaymentOptions?.length > 0) {
      setPricingPlanPayload((prevState) => ({
        ...prevState,
        pricingPlanTypeTabValue: filteredPaymentOptions[0].name
      }));
    }
    setPlanDataAndPrefillPricingPlanName();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(updatePlanGroupNameAttribute(initialPlanGroupNameAttribute));
      dispatch(
        updatePrivatePlanConfigAttributes(initialPrivatePlanConfigAttributes)
      );
      dispatch(updatePrivatePlanConfigPayload(initialPrivatePlanConfigPayload));
      dispatch(updateDeletedCompanyId(''));
    };
  }, []);

  const setPlanDataAndPrefillPricingPlanName = () => {
    const clonedPricingPlanData = _.cloneDeep(pricingPlanData);
    if (
      !editPlan &&
      onBoardingFormData.category === idConstants.automotives.productCategoryName
    ) {
      clonedPricingPlanData?.sections
        ?.find((section) => section.name === pricingConstants?.basicPlanInformation)
        ?.attributes?.forEach((attribute) => {
          if (attribute.name === FieldConstants?.planName)
            attribute.value = clonedPricingPlanData?.initialValue?.planName;
        });
    }
    setPlanData(clonedPricingPlanData);
    setPlanDataCopy(clonedPricingPlanData);
  };

  const handleSideBar = () => {
    setShowSideBar((prev) => !prev);
    setShowPricingPlanModal();
  };

  const updateProductStatusToDraft = () => {
    if (
      prodBasicInfo?.status?.statusId === idConstants.rejected.statusId ||
      (editPlan &&
        prodBasicInfo?.status?.statusId === idConstants.submitted.statusId &&
        apiPlans.filter(
          (plan) => plan.status.description === idConstants.submitted.description
        ).length === 1)
    ) {
      dispatch(
        updateProductStatus(
          idConstants.drafted.statusId,
          prodBasicInfo.productId,
          categoryId,
          prodBasicInfo?.companyNo
        )
      );
    }
  };

  const createPlan = (state) => {
    dispatch(
      createPricingPlan(state, prodId, categoryId, GAConstants.isTriggerGAEvent)
    ).then((resp) => {
      if (resp) {
        if (
          state.currentPlan.planGroupName === PRICING.PLAN_GROUP_TYPE.PRIVATE.VALUE
        ) {
          dispatch(
            addPrivatePlanDetails(resp.planId, privatePlanConfigPayload)
          ).then(() => {
            dispatch(getApiPlans(prodId));
          });
        }
        dispatch(
          auditLogging(
            idConstants.auditLogsModuleIds.pricingModuleId,
            state,
            'request',
            'success',
            categoryId
          )
        );
        updateProductStatusToDraft();
      }
    });
  };

  const updatePrivatePlan = (plan) => {
    if (plan.planGroupName !== PRICING.PLAN_GROUP_TYPE.PRIVATE.VALUE) return;
    if (
      _.isEqual(
        editPlan?.privatePlanDetails.tenantId,
        privatePlanConfigPayload.tenantId
      ) &&
      _.isEqual(
        editPlan?.privatePlanDetails.companyDetails,
        privatePlanConfigPayload.companyId
      )
    )
      return;
    dispatch(updatePrivatePlanDetails(plan.planId, privatePlanConfigPayload));
  };

  const updatePlan = (state) => {
    dispatch(
      updatePricingPlan(state, prodId, categoryId, GAConstants.isTriggerGAEvent)
    ).then((response) => {
      if (response) {
        updatePrivatePlan(response);
        updateProductStatusToDraft();
        const planData = apiPlans.find(
          (plan) => plan.planId === state.currentPlan.planId
        );
        if (planData.status.description !== idConstants.drafted.description) {
          dispatch(
            updatePricingStatus(
              [state.currentPlan.planId],
              prodBasicInfo.productId,
              idConstants.drafted.statusId
            )
          ).then((statusResponse) => {
            if (statusResponse) {
              dispatch(getApiPlans(prodBasicInfo.productId));
            }
          });
        } else {
          dispatch(getApiPlans(prodBasicInfo.productId));
        }
      }
    });
  };

  const nextHandler = () => {
    if (planData.sections && planData.sections.length - 1 !== currentPage) {
      setCurrentPage((prevState) => prevState + 1);
    } else {
      handleSideBar();
      setShowPricingPlanModal();
      const payload = { ...pricingPlanPayload };
      if (!payload?.currentPlan?.price) {
        payload.currentPlan.price = 0;
      }

      if (!payload?.currentPlan?.overUsagePrice) {
        payload.currentPlan.overUsagePrice = 0;
      }
      if (
        payload?.currentPlan?.planDetails.planType ===
        idConstants.pricingPlanType.usageBased
      ) {
        payload.priceSlabs = priceSlabs;
      }
      if (
        payload.pricingPlanTypeTabValue ===
        idConstants.oneTimeBillingType.billingTypeCode
      ) {
        payload.currentPlan.dataBandwidth = 0;
        payload.currentPlan.consumption = idConstants.pricing.notApplicable;
      }

      if (
        payload.pricingPlanTypeTabValue ===
        idConstants.oneTimePostBillingType.billingTypeCode
      ) {
        payload.currentPlan.consumption = idConstants.pricing.notApplicable;
      }
      if (
        payload?.currentPlan?.rateLimitType ===
        idConstants.pricing.consumptionNoMeasurementType
      )
        payload.currentPlan.quota = '';
      payload.currentPlan.userDetails = {
        userId: getUserId(),
        typeOfUser: idConstants.typeOfUsers.b2b,
        b2bIdentifier: prodBasicInfo?.companyNo
      };
      if (editPlan) {
        updatePlan(payload);
      } else {
        createPlan(payload);
      }
    }
  };

  const prevHandler = () => {
    setCurrentPage((prevState) => prevState - 1);
  };

  const isValidJSONString = (str) => {
    try {
      JSON.parse(str);
    } catch (error) {
      return false;
    }
    return true;
  };

  const handleDisbleNextBtn = (data) => {
    let fields = [];
    if (data.name === 'paymentOptions') {
      if (data && data?.planTypeTabs?.length > 0) {
        const currentElement = data?.planTypeTabs?.findIndex(
          (item) => item?.name === currentTab
        );
        if (
          data?.planTypeTabs[currentElement] &&
          data?.planTypeTabs[currentElement]?.groups?.length > 0
        ) {
          data?.planTypeTabs[currentElement].groups.forEach((element) => {
            element.attributes?.forEach((event) => {
              if (
                event.attributeType === DynamicFieldConstants.TOGGLE &&
                event.name !== 'addAConsumptionBasedPrice' &&
                pricingPlanPayload?.currentPlan?.rateLimitType !==
                  idConstants.pricing.consumptionNoMeasurementType
              ) {
                event?.attributes?.forEach((attributeData) => {
                  if (
                    attributeData.attributeType !== DynamicFieldConstants.TOGGLE &&
                    event.name === 'addAFixedPrice' &&
                    pricingPlanPayload?.currentPlan?.rateLimitType ===
                      idConstants.pricing.consumptionNoMeasurementType
                  )
                    fields = fields.concat(attributeData);
                });
              } else {
                if (isEnabled) {
                  if (
                    event?.name !== 'consumptionQuota' &&
                    event?.name !== 'addAConsumptionBasedPrice'
                  )
                    fields = fields.concat(event);

                  if (
                    pricingPlanPayload?.currentPlan?.rateLimitType ===
                      idConstants.pricing.consumptionNoMeasurementType &&
                    event?.name === 'addAFixedPrice'
                  )
                    fields = fields.concat(event?.attributes);
                } else {
                  if (event?.name !== 'addAConsumptionBasedPrice')
                    return (fields = fields.concat(event));

                  if (enableInputs?.name === event?.name)
                    return (fields = fields.concat(event?.attributes));

                  if (
                    enableInputs?.name !== event?.name &&
                    enableInputs?.value &&
                    event?.name === 'addAConsumptionBasedPrice'
                  )
                    return (fields = fields.concat(event?.attributes));
                }
              }
            });
          });
        }

        if (
          data?.planTypeTabs[currentElement] &&
          data?.planTypeTabs[currentElement]?.attributes?.length > 0
        ) {
          data?.planTypeTabs[currentElement].attributes?.forEach((event) => {
            if (event.attributeType !== DynamicFieldConstants.TOGGLE)
              fields = fields.concat(event);
          });
        }
        if (
          data?.planTypeTabs[currentElement] &&
          data?.planTypeTabs[currentElement]?.groups?.length === 0 &&
          data?.planTypeTabs[currentElement]?.name ===
            idConstants.pricingPlanType.customPlans
        ) {
          data?.planTypeTabs[currentElement]?.attributes.forEach((element) => {
            fields = fields.concat(element);
          });
        }
      }
    } else {
      if (data && data?.groups?.length > 0) {
        data.groups.forEach((element) => {
          fields = fields.concat(element.attributes);
        });
      }
      if (data && data?.attributes?.length > 0) {
        fields = fields.concat(data.attributes);
        data.attributes?.forEach((event) => {
          if (event?.attributes?.length > 0) {
            fields = fields.concat(event.attributes);
          }
        });
      }
    }
    return checkPreventNext(fields, data);
  };

  const checkPreventNext = (fields, data) => {
    let isPreventNextPage;
    let pricingSection = [];
    const allowedFieldsName = getValidationFieldsInPaymentOptions();
    fields?.forEach((fieldsData) => {
      if (allowedFieldsName.includes(fieldsData?.name)) {
        pricingSection.push(fieldsData);
      }
    });
    for (const element of fields) {
      let rules = {};
      if (element?.validation) {
        rules = isValidJSONString(element?.validation)
          ? JSON.parse(element?.validation)
          : element?.validation;
      }
      const required = rules?.rule?.required;
      if (
        (required?.isRequired &&
          (element?.value === false ||
            element?.value === undefined ||
            element.value === null ||
            element?.value?.length === 0)) ||
        (element?.error?.length && element?.error?.length !== 0)
      ) {
        isPreventNextPage = true;
        break;
      }
    }

    // Checking private plan config fields
    if (
      pricingPlanPayload.currentPlan.planGroupName ===
        PRICING.PLAN_GROUP_TYPE.PRIVATE.VALUE &&
      !privatePlanConfigPayload.tenantId
    ) {
      isPreventNextPage = true;
    }
    if (!prodId && currentPage === planData.sections.length - 1) {
      isPreventNextPage = true;
    }

    if (fields.length === 0) {
      isPreventNextPage = true;
    }

    const validation =
      pricingPlanPayload?.pricingPlanTypeTabValue ===
      idConstants.freePlanBillingType.billingTypeCode
        ? true
        : pricingSection?.some((pricingSectionData) => pricingSectionData?.value);
    if (
      data.name === 'paymentOptions' &&
      (!isPricingSlabsValid || !validation) &&
      pricingPlanPayload?.pricingPlanTypeTabValue !==
        idConstants.pricingPlanType.customPlans
    ) {
      isPreventNextPage = true;
    }

    if (data.name === 'paymentOptions' && isBasePriceLessThanZero()) {
      isPreventNextPage = true;
    }

    return isPreventNextPage;
  };

  const isBasePriceLessThanZero = () => {
    if (
      pricingPlanPayload?.currentPlan?.consumption ===
        idConstants.pricing.definedByUsage &&
      !pricingPlanPayload?.currentPlan?.price &&
      pricingPlanPayload?.currentPlan?.rateLimitType !==
        idConstants?.pricing?.consumptionNoMeasurementType
    ) {
      if (!pricingPlanPayload?.currentPlan?.chargeableUnit?.chargeableUnitType) {
        return true;
      } else {
        const allPricesAreZero = priceSlabs.every(
          (item) => parseFloat(item.cost) === 0
        );

        if (allPricesAreZero) {
          return true;
        }
      }
    }
  };

  const updatePricingPlanPayload = (key, value, order, subkey) => {
    if (order === 1) {
      setPricingPlanPayload((prevState) => {
        return {
          ...prevState,
          currentPlan: {
            ...prevState.currentPlan,
            [key]: value
          }
        };
      });
    } else if (
      order === 2 &&
      subkey === 'chargeableUnitType' &&
      pricingPlanPayload.currentPlan.chargeableUnit.chargeableUnitType !== value
    ) {
      setPricingPlanPayload((prevState) => {
        return {
          ...prevState,
          currentPlan: {
            ...prevState.currentPlan,
            [key]: { [subkey]: value }
          },
          priceSlabs: [defaultSlabDetails]
        };
      });
    } else if (order === 2 && key === 'OccurrenceCount') {
      setPricingPlanPayload((prevState) => {
        return {
          ...prevState,
          currentPlan: {
            ...prevState.currentPlan,
            planDetails: {
              ...prevState.currentPlan.planDetails,
              occurrence: value
            }
          }
        };
      });
    } else if (order === 2) {
      setPricingPlanPayload((prevState) => {
        return {
          ...prevState,
          currentPlan: {
            ...prevState.currentPlan,
            [key]: { [subkey]: value }
          }
        };
      });
    } else if (order === 3 && value !== idConstants.pricingPlanType.customPlans) {
      setPricingPlanPayload((prevState) => {
        return {
          ...prevState,
          [key]: value
        };
      });
    } else if (order === 3 && value === idConstants.pricingPlanType.customPlans) {
      setPricingPlanPayload((prevState) => {
        return {
          ...prevState,
          currentPlan: {
            ...prevState.currentPlan
          },
          [key]: value
        };
      });
    } else if (
      order === 4 &&
      key === pricingConstants.additionalPricingPolicy &&
      value
    ) {
      setPricingPlanPayload((prevState) => {
        return {
          ...prevState,
          currentPlan: {
            ...prevState.currentPlan,
            [key]: {
              ...prevState.currentPlan?.additionalPricingPolicy,
              [subkey]: String(value)
            }
          }
        };
      });
    }
  };

  const handleFieldChange = (event, values, tab) => {
    if (
      event === DynamicFieldConstants.NUMBER ||
      event === DynamicFieldConstants.TOGGLE
    )
      setEnableInputs(values);
    setCurrentTab(tab ? tab?.name : pricingPlanPayload?.pricingPlanTypeTabValue);
    const planDataCpy = { ...planData };
    if (values.group) {
      const findGroupIndex = planDataCpy['sections'][currentPage][
        'groups'
      ].findIndex((item) => item.name === values.groupID);

      const findValIndex = planDataCpy['sections'][currentPage]['groups'][
        findGroupIndex
      ]['attributes'].findIndex((item) => item.name === values.name);

      planDataCpy['sections'][currentPage]['groups'][findGroupIndex]['attributes'][
        findValIndex
      ]['value'] = event.value;
      planDataCpy['sections'][currentPage]['groups'][findGroupIndex]['attributes'][
        findValIndex
      ]['error'] = event.error;
    } else {
      const findFieldIndex = planDataCpy['sections'][currentPage][
        'attributes'
      ].findIndex((item) => item.name === values.name);

      if (findFieldIndex >= 0) {
        planDataCpy['sections'][currentPage]['attributes'][findFieldIndex]['value'] =
          event.value;
        planDataCpy['sections'][currentPage]['attributes'][findFieldIndex]['error'] =
          event.error;
      } else {
        allTabs = planDataCpy['sections'][currentPage]['planTypeTabs'];
        let currentFieldTab = tab;
        if (currentFieldTab?.groups && currentFieldTab?.groups?.length > 0) {
          currentFieldTab.groups.forEach((field) => {
            field?.attributes?.forEach((attribute) => {
              if (attribute?.attributeType === DynamicFieldConstants.TOGGLE) {
                attribute?.attributes?.forEach((attributedata) => {
                  if (attribute.name === values?.name) {
                    if (typeof values?.value == 'number') {
                      attributedata.value = values?.value;
                      attributedata.error = values?.error;
                    } else {
                      attributedata.value = '';
                    }
                  } else if (
                    attributedata.name === values?.name &&
                    (values?.value >= 0 && typeof values?.value == 'number'
                      ? values?.value >= 0
                      : values?.value)
                  ) {
                    attributedata.value =
                      values?.value?.name !== undefined
                        ? values?.value?.name
                        : values?.value;
                    attributedata.error = values?.error;
                  } else if (
                    !values?.value &&
                    attributedata.name !== 'pricingType' &&
                    values?.name === attributedata?.name
                  ) {
                    attributedata.value = '';
                  } else if (
                    values?.value?.name ===
                      idConstants.pricing.consumptionNoMeasurementType &&
                    attributedata.name === 'pricingType'
                  ) {
                    attributedata.value = '';
                  } else if (
                    attributedata.name === values?.name &&
                    attributedata.attributeType ===
                      DynamicFieldConstants.SINGLE_SELECT
                  )
                    attributedata.value = values?.value;
                });
              } else if (attribute.name === values?.name) {
                attribute.value =
                  values?.value?.name !== undefined
                    ? values?.value?.name
                    : values?.value;
                attribute.error = values?.error;
              }
            });
          });
        } else {
          currentFieldTab?.attributes?.forEach((attribute) => {
            if (attribute.name === values?.name) {
              attribute.value = values?.value?.name
                ? values?.value?.name
                : values?.value;
              attribute.error = values?.error;
            }
          });
        }
        allTabs = [currentFieldTab];
        planDataCpy['sections'][currentPage]['tabs'] = allTabs;
      }
    }
    handleValues(event, values.groupID);
    setPlanData(planDataCpy);
    const eventName = values?.name ? values.name : values;
    switch (eventName) {
      case idConstants.pricingPlanType.customPlans:
      case idConstants.pricingPlanType.flatRate:
      case idConstants.pricingPlanType.usageBased:
      case idConstants.pricingPlanType.freePlan:
        updatePricingPlanPayload('pricingPlanTypeTabValue', eventName, 3);
        updatePricingPlanPayload(
          'isSlabPricing',
          eventName === idConstants.pricingPlanType.usageBased ? true : false,
          1
        );
        break;
      case 'OccurrenceCount':
        updatePricingPlanPayload('OccurrenceCount', values.value, 2);
        break;
      case 'consumptionModel':
        updatePricingPlanPayload('consumption', values?.value?.name, 1);
        break;
      case 'consumptionUnit':
        if (values?.value?.name === idConstants.pricing.consumptionNoMeasurementType)
          updatePricingPlanPayload('chargeableUnit', '', 2, 'chargeableUnitType');

        return updatePricingPlanPayload('rateLimitType', values?.value?.name, 1);
      case 'netPrice':
        if (values?.value) {
          updatePricingPlanPayload('price', values.value, 1);
          break;
        } else {
          updatePricingPlanPayload('price', 0, 1);
          break;
        }
      case 'activationNetPrice':
        if (values?.value >= 0) updatePricingPlanPayload('isSoftLimit', true, 1);
        else updatePricingPlanPayload('isSoftLimit', false, 1);

        updatePricingPlanPayload('overUsagePrice', values?.value, 1);
        break;
      case 'consumptionQuota':
        updatePricingPlanPayload('quota', values?.value?.name, 1);
        break;
      case 'billingCycle':
        updatePricingPlanPayload('billingCycleType', values?.value?.name, 1);
        if (
          tab.name === idConstants.pricingPlanType.usageBased &&
          values?.value?.name === idConstants.oneTimeBillingType.billingTypeCode
        )
          updatePricingPlanPayload(
            'consumption',
            idConstants.pricing.definedByUsage,
            1
          );
        break;
      case 'currency':
        updatePricingPlanPayload('currencyCode', values?.value?.name, 1);
        break;
      case 'pricingType':
        if (values?.value) {
          updatePricingPlanPayload('isSlabPricing', true, 1);
          updatePricingPlanPayload(
            'chargeableUnit',
            values?.value?.name,
            2,
            'chargeableUnitType'
          );
        } else {
          updatePricingPlanPayload('isSlabPricing', false, 1);
          updatePricingPlanPayload('chargeableUnit', '', 2, 'chargeableUnitType');
        }

        break;
      case 'addAFixedPrice':
        updatePricingPlanPayload('price', '', 1);
        break;
      case 'addAConsumptionBasedPrice':
        updatePricingPlanPayload('chargeableUnit', '', 2, 'chargeableUnitType');
        break;
      case 'planName':
        updatePricingPlanPayload('planName', event.value, 1);
        break;
      case 'writeDescriptionAboutPlan':
        updatePricingPlanPayload('description', event.value, 1);
        break;
      case idConstants.recurringBillingType.billingTypeCode:
        updatePricingPlanPayload(
          'pricingPlanTypeTabValue',
          idConstants.recurringBillingType.billingTypeCode,
          3
        );
        break;
      case 'FLAT':
        updatePricingPlanPayload('pricingPlanTypeTabValue', 'FLAT', 3);
        break;
      case idConstants.oneTimeBillingType.billingTypeCode:
        updatePricingPlanPayload('pricingPlanTypeTabValue', 'ONE_TIME_PURCHASE', 3);
        break;
      case idConstants.oneTimePostBillingType.billingTypeCode:
        updatePricingPlanPayload(
          'pricingPlanTypeTabValue',
          idConstants.oneTimePostBillingType.billingTypeCode,
          3
        );
        break;
      case idConstants.contactSalesBillingType.billingTypeCode:
        updatePricingPlanPayload(
          'pricingPlanTypeTabValue',
          idConstants.contactSalesBillingType.billingTypeCode,
          3
        );
        break;
      case idConstants.freePlanBillingType.billingTypeCode:
        updatePricingPlanPayload(
          'pricingPlanTypeTabValue',
          idConstants.freePlanBillingType.billingTypeCode,
          3
        );
        break;
      case pricingConstants.recommendedRetailPrice:
      case pricingConstants.sellOutPrice:
        updatePricingPlanPayload(
          pricingConstants.additionalPricingPolicy,
          event?.value,
          4,
          eventName
        );
        break;
      case pricingConstants.planGroup:
      case pricingConstants.unitOfMeasurement:
        updatePricingPlanPayload(
          pricingConstants.additionalPricingPolicy,
          event?.value?.value,
          4,
          eventName
        );
        break;
    }
  };

  const handleValues = (event, groupId) => {
    const eventData = ['netPrice', 'currency', 'planName', 'planDetailedItem'];
    if (eventData.includes(event?.name)) {
      if (event?.name === 'planDetailedItem') {
        const data = [...previewData.planDetailedItem];
        const findIdIndex = data.findIndex((item) => item.id === groupId);
        if (findIdIndex === -1) {
          data.push({
            id: groupId,
            benefitDescription: event.value,
            benefitOrder: data?.length
          });
        } else {
          data[findIdIndex].benefitDescription = event.value;
        }
        setPricingPlanPayload((prevState) => ({
          ...prevState,
          currentPlan: {
            ...prevState.currentPlan,
            planBenefits: data
          }
        }));
        setPreviewData((prev) => ({
          ...prev,
          planDetailedItem: [...data]
        }));
      } else {
        setPreviewData((prev) => ({ ...prev, [event.name]: event.value }));
      }
    }
  };

  const handleGroupData = (groupDataCpy) => {
    const planDataCpy = JSON.parse(JSON.stringify(planData));
    planDataCpy['sections'][currentPage]['groups'] = groupDataCpy;
    let orderData = [];
    groupDataCpy?.forEach?.((data, index) => {
      orderData.push({
        id: data?.name,
        benefitDescription: data?.attributes[0]?.value,
        benefitOrder: index
      });
    });
    setPricingPlanPayload((prevState) => ({
      ...prevState,
      currentPlan: {
        ...prevState.currentPlan,
        planBenefits: orderData
      }
    }));
    setPlanData(planDataCpy);
  };

  const handleDeleteGroup = (event, updatedData) => {
    const data = [...previewData.planDetailedItem];
    const filterGroupId = data.filter((item) => item.id !== event.name);
    setPricingPlanPayload((prevState) => ({
      ...prevState,
      currentPlan: {
        ...prevState.currentPlan,
        planBenefits: filterGroupId
      }
    }));
    setPreviewData((prev) => ({
      ...prev,
      planDetailedItem: [...filterGroupId]
    }));
    handleGroupData(updatedData);
  };

  const findBasicIndex = planData?.sections?.findIndex(
    (event) => event.name === 'basicPlanInformation'
  );

  const findPaymentIndex = planData?.sections?.findIndex(
    (event) => event.name === 'paymentOptions'
  );

  const pricingPreview = (
    <div className='pricing-plan__right'>
      <div className='pricing-plan__right-text'>
        <p className='pricing-plan__right-heading'>
          {i18next.t('DYNAMIC_FORM.PRICING_PLAN.GENERIC_MARKETPLACE_PREVIEW')}
        </p>
      </div>
      <div className='pricing-plan__right-plan-preview'>
        <PricingPlanPreview
          plan={pricingPlanPayload.currentPlan}
          priceSlabs={priceSlabs}
          pricingPlanPayload={pricingPlanPayload.pricingPlanTypeTabValue}
        />
      </div>
      <p className='pricing-plan__right-subheading'>
        {i18next.t(
          'DYNAMIC_FORM.PRICING_PLAN.THIS_IS_HOW_YOUR_PLAN_LOOKS_LIKE_TO_THE_END_USERS_IN_MARKETPLACE'
        )}
      </p>
    </div>
  );

  const setPricingSlabs = (pricingSlabs) => {
    setPriceSlabs(pricingSlabs);
  };

  const setTableValidation = (isValid) => {
    setIsPricingSlabsValid(isValid);
  };

  const isEnabledFunction = (
    type,
    parentType,
    consumptionUnitValue,
    planOccurrence,
    toggleData,
    data,
    getDisabledFunction
  ) => {
    if (
      (type === DynamicFieldConstants.SINGLE_SELECT ||
        type === DynamicFieldConstants.TOGGLE) &&
      (consumptionUnitValue === idConstants.pricing.consumptionNoMeasurementType ||
        ((currentTab === idConstants.pricingPlanType.flatRate ||
          currentTab === idConstants.pricingPlanType.usageBased) &&
          planOccurrence === '1')) &&
      (data.name === 'consumptionQuota' ||
        data?.name === 'addAConsumptionBasedPrice')
    ) {
      setIsEnabled(true);
      return true;
    }

    if (
      (type === DynamicFieldConstants.SINGLE_SELECT ||
        type === DynamicFieldConstants.TOGGLE) &&
      consumptionUnitValue !== idConstants.pricing.consumptionNoMeasurementType &&
      (data.name === 'consumptionQuota' ||
        data?.name === 'addAConsumptionBasedPrice')
    ) {
      setIsEnabled(false);
      return false;
    }

    if (type === DynamicFieldConstants.SINGLE_SELECT)
      return getDisabledFunction(parentType, toggleData);
  };

  const handlePrefillValue = () => {
    const planSection = _.cloneDeep(planDataCopy);

    if (!planSection?.sections) return;
    const currentPageAttributes = planSection.sections[currentPage]?.attributes;

    const basicPlanInformation = planSection.sections.find(
      (section) => section.name === pricingConstants.basicPlanInformation
    );

    const paymentOptions = planSection.sections.find(
      (section) => section.name === pricingConstants.paymentOptions
    );

    if (currentPageAttributes) {
      for (const attribute of currentPageAttributes) {
        if (editPlan.hasOwnProperty(attribute.name)) {
          attribute.value = editPlan[attribute.name];
        }
        if (attribute.name === pricingConstants.writeDescriptionAboutPlan) {
          attribute.value = editPlan?.description;
        }
        if (
          [
            pricingConstants?.recommendedRetailPrice,
            pricingConstants?.sellOutPrice,
            pricingConstants?.planGroup,
            pricingConstants?.unitOfMeasurement
          ]?.includes(attribute?.name) &&
          editPlan.hasOwnProperty(pricingConstants?.additionalPricingPolicy)
        ) {
          attribute.value =
            attribute?.name === pricingConstants?.unitOfMeasurement
              ? editPlan?.rateLimit?.rateLimitType?.limitType
              : editPlan?.additionalPricingPolicy[attribute?.name];
        }
      }
    }

    if (basicPlanInformation) {
      const basicPlanGroup = basicPlanInformation.groups[0];
      const { planBenefits } = editPlan || [];
      const groups = [];

      for (let i = 0; i < planBenefits?.length; i++) {
        const copyElement = _.cloneDeep(basicPlanGroup);
        copyElement.name = planBenefits[i].planBenefitId;
        const fieldData = copyElement.attributes.map((fieldItem) => {
          fieldItem.error = '';
          fieldItem.value = planBenefits[i]?.benefitDescription;
          return fieldItem;
        });
        copyElement.attributes = fieldData;
        groups.push(copyElement);
      }

      if (groups?.length > 0) {
        basicPlanInformation.groups = groups;
      }
    }

    if (paymentOptions) {
      for (const tab of paymentOptions.planTypeTabs || []) {
        if (tab.name === editPlan?.planDetails?.planType) {
          for (const attribute of tab.attributes || []) {
            switch (attribute.name) {
              case pricingConstants.billingCycle:
                attribute.value = editPlan?.planBillingCycle?.billingCycleType;
                break;
              case pricingConstants.currency:
                attribute.value = editPlan?.currency?.currencyCode;
                break;
              case pricingConstants.consumptionUnit:
                attribute.value = editPlan?.rateLimit?.rateLimitType?.limitType;
                break;
              case pricingConstants.occurrence:
                attribute.value = editPlan?.planDetails?.occurrence;
                break;
              case pricingConstants.netPrice:
                attribute.value = Number(editPlan?.planCost);
                break;
            }
          }

          for (const group of tab.groups || []) {
            for (const attribute of group.attributes || []) {
              switch (group.name) {
                case pricingConstants.pricingSection:
                  if (attribute.name === pricingConstants.currency) {
                    attribute.value = editPlan?.currency?.currencyCode;
                  }
                  if (attribute.attributeType === 'TOGGLE') {
                    for (const toggleAttribute of attribute.attributes || []) {
                      switch (toggleAttribute.name) {
                        case pricingConstants.netPrice:
                          toggleAttribute.value = Number(editPlan?.planCost);
                          break;
                        case pricingConstants.pricingType:
                          toggleAttribute.value =
                            editPlan?.rateLimit?.chargeableUnit?.chargeableUnitType;
                          break;
                      }
                    }
                  }
                  if (attribute.name === pricingConstants.netPrice) {
                    attribute.value = Number(editPlan?.planCost);
                  }
                  break;
                case pricingConstants.billingAndConsumption:
                  switch (attribute.name) {
                    case pricingConstants.billingCycle:
                      attribute.value = editPlan?.planBillingCycle?.billingCycleType;
                      break;
                    case pricingConstants.consumptionUnit:
                      attribute.value =
                        editPlan?.rateLimit?.rateLimitType?.limitType;
                      break;
                    case pricingConstants.consumptionQuota:
                      attribute.value = editPlan?.rateLimit?.timeUnit?.timeUnitCode;
                      break;
                    case pricingConstants.consumptionModel:
                      attribute.value = editPlan?.consumption;
                      break;
                    case pricingConstants.occurrence:
                      attribute.value = editPlan?.planDetails?.occurrence;
                      break;
                  }
                  break;

                case pricingConstants.billingCycle:
                  if (attribute.name === pricingConstants.billingCycle) {
                    attribute.value = editPlan?.planBillingCycle?.billingCycleType;
                  }
                  break;
                case pricingConstants.currency:
                  if (attribute.name === pricingConstants.currency) {
                    attribute.value = editPlan?.currency?.currencyCode;
                  }
                  break;
              }
            }
          }
        }
      }
    }
    setPlanData(planSection);
  };

  const getNextBtnLabel = () => {
    if (currentPage === planData.sections.length - 1) {
      if (editPlan) {
        return i18next.t('DYNAMIC_FORM.PRICING_PLAN.UPDATE_PRICING_PLAN');
      } else {
        return i18next.t('DYNAMIC_FORM.PRICING_PLAN.ADD_PRICING_PLAN');
      }
    } else {
      return i18next.t('DYNAMIC_FORM.SHARED.BUTTON.NEXT');
    }
  };

  return (
    <>
      {loader && <Loader />}
      {showSideBar && planData && planData.sections && (
        <SideBar
          label={
            editPlan
              ? i18next.t('DYNAMIC_FORM.PRICING_PLAN.UPDATE_PRICING_PLAN')
              : i18next.t('DYNAMIC_FORM.PRICING_PLAN.ADD_PRICING_PLAN')
          }
          currentPage={currentPage}
          handleNext={nextHandler}
          handlePrevious={prevHandler}
          handleClose={() => handleSideBar()}
          isShowPrevStepBtn={currentPage !== 0}
          handleSideBar={handleSideBar}
          isShowCancelBtn={true}
          nextStepDisabled={handleDisbleNextBtn(
            planData?.sections?.length > 0 ? planData['sections'][currentPage] : ''
          )}
          nextBtnLabel={getNextBtnLabel()}
          childrenClass='sidebar__children'
          customClass='sidebar__custom'
        >
          <div className='pricing-plan-wholeDiv'>
            <div className='pricing_plan m-step-indicator -small'>
              <StepIndicator
                label={i18next.t('DYNAMIC_FORM.LABEL.SUBMIT')}
                data={planData.sections}
                active={currentPage}
                prevTrigger={prevHandler}
                nextTrigger={nextHandler}
              />
            </div>
            {currentPage === 0 && (
              <div
                id='basic-plan-wrapper' // Used for scrolling
                className='pricing-plan-basicplan__wrapper'
              >
                <BasicPlan
                  handleGroupData={handleGroupData}
                  handleDeleteGroup={handleDeleteGroup}
                  handleFieldChange={(event, values) =>
                    handleFieldChange(event, values)
                  }
                  updatePricingPlanPayload={updatePricingPlanPayload}
                  item={planData?.sections[findBasicIndex]}
                />
              </div>
            )}
            {currentPage === 1 && (
              <div className='pricing-plan-paymentoptions__wrapper'>
                <PaymentOptions
                  handleFieldChange={handleFieldChange}
                  item={planData?.sections[findPaymentIndex]}
                  masterData={masterData}
                  pricingPlanPayload={pricingPlanPayload}
                  onBoardingFormData={onBoardingFormData}
                  setPricingSlabs={setPricingSlabs}
                  setTableValidation={setTableValidation}
                  isEnabledFunction={isEnabledFunction}
                  pricingPlans={apiPlans}
                  isBasePriceLessThanZero={isBasePriceLessThanZero}
                  setPricingPlanPayload={setPricingPlanPayload}
                  currentPage={currentPage}
                  editPlan={editPlan}
                />
              </div>
            )}
          </div>
          <div className='pricing-plan-previewrapper'>{pricingPreview}</div>
        </SideBar>
      )}
    </>
  );
};

export default PricingPlanScreen;
