import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import './index.scss';
import {
  Dropdown,
  TextInput,
  ToggleButton,
  NumberInput
} from '../../../../msreact-kit/packages/html-components';
import PricingSlabsTable from '../../../api/AddAPI/Pricing/PricingSlabsTable';
import { DynamicFieldConstants } from '../../../../shared/constants';
import { sortByDisplayOrder } from '../../../../utils';
import { defaultSlabDetails } from '../../../../data/appConstants';
import { getRegionCode } from '../../../../utils/get-region-details';

// Constants
import idConstants, { PRICING } from '../../../../data/idConstants';

const PaymentOptions = ({
  handleFieldChange,
  item,
  children,
  masterData,
  pricingPlanPayload,
  onBoardingFormData,
  setPricingSlabs,
  setTableValidation,
  isEnabledFunction,
  pricingPlans,
  isBasePriceLessThanZero,
  setPricingPlanPayload,
  editPlan
}) => {
  const [currentPlan, setCurrentPlan] = useState([]);
  const [enableInputs, setEnableInputs] = useState({
    error: '',
    name: '',
    value: ''
  });
  const [selectedChargeableUnit, setSelectedChargeableUnit] = useState('');
  const [keyName, setKeyName] = useState([]);
  const [tabOptions, setTabOptions] = useState([]);
  const currencyArray = idConstants?.currencyOptions?.find(
    (item) => item.regionCode === getRegionCode()
  )?.currencies;
  const filteredCurrencies = masterData?.currency?.filter((item) =>
    currencyArray?.includes(item.name)
  );
  const [currencyDropdownOptions, setCurrencyDropdownOptions] = useState([]);
  const [planActive, setPlanActive] = useState('');
  const [paymentTypeActive, setPaymentTypeActive] = useState('');
  const [paymentModeActive, setPaymentModeActive] = useState('');
  const [planOccurrence, setPlanOccurrence] = useState('0');
  const [occurrenceDropDownValues, setOccurrenceDropDownValues] = useState([]);

  useEffect(() => {
    setCurrencyDropdownOptions(
      filteredCurrencies?.map(({ name, id, displayName }) => ({
        label: displayName,
        id: id,
        name: name
      }))
    );
  }, []);

  const isCustomPlanOrFreePlanAvailable = (planType) => {
    return (
      pricingPlans?.length > 0 &&
      (planType === idConstants.pricingPlanType.freePlan ||
        planType === idConstants.pricingPlanType.customPlans) &&
      pricingPlans.some((item) => item?.planDetails?.planType === planType)
    );
  };

  useEffect(() => {
    const sectionData = sortByDisplayOrder(item?.planTypeTabs);
    let filteredPaymentOptions = [];
    let breakCondition = false;
    sectionData?.forEach((tabItem) => {
      masterData?.plan_type?.forEach((planTypeItem, planTypeIndex) => {
        if (tabItem.name === planTypeItem.name) {
          filteredPaymentOptions.push(tabItem);
          if (editPlan) {
            setPlanActive(editPlan?.planDetails?.planType);
            handleFieldChange(editPlan?.planDetails?.planType, {
              name: editPlan?.planDetails?.planType,
              currentTabIndex: planTypeIndex
            });
            setPaymentTypeActive(editPlan?.planDetails?.paymentType);
            setPaymentModeActive(editPlan?.planDetails?.paymentModePref);
            setPlanOccurrence(editPlan?.planDetails?.occurrence);
            setPricingPlanPayload((prevState) => ({
              ...prevState,
              pricingPlanTypeTabValue: editPlan?.planDetails?.planType
            }));
            setSelectedChargeableUnit(
              editPlan?.rateLimit?.chargeableUnit?.chargeableUnitType
            );
          } else {
            if (
              planActive === '' &&
              !isCustomPlanOrFreePlanAvailable(planTypeItem.name) &&
              !breakCondition
            ) {
              breakCondition = true;
              setPlanActive(planTypeItem.name);
              setActivePlanType(planTypeItem.name);
              handleFieldChange(planTypeItem.name, {
                name: planTypeItem.name,
                currentTabIndex: 0
              });
            }
          }
          if (
            idConstants.pricingPlanType.customPlans === planTypeItem.name ||
            planTypeItem.name === idConstants.pricingPlanType.freePlan ||
            planTypeItem.name === idConstants.pricingPlanType.freePolicy
          ) {
            if (isCustomPlanOrFreePlanAvailable(planTypeItem.name)) {
              if (
                editPlan?.planDetails?.planType ===
                  idConstants.pricingPlanType.freePlan &&
                planTypeItem.name === idConstants.pricingPlanType.freePlan
              ) {
                tabItem.isDisabled = false;
              } else {
                tabItem.isDisabled = true;
              }
            }
          }
        }
      });
    });

    filteredPaymentOptions.forEach((tabs) => {
      const tabsObj = tabs?.groups?.length > 0 ? tabs?.groups : tabs?.attributes;
      sortByDisplayOrder(tabsObj).forEach((sortedTab) => {
        if (sortedTab?.attributeType === 'OPTION')
          sortByDisplayOrder(sortedTab?.attributes);

        return '';
      });
    });
    setTabOptions(filteredPaymentOptions);
  }, [item]);

  const handleItemClick = (paymentType, index, isDisabled, tab) => {
    if (paymentType === planActive || isDisabled) return;

    handleFieldChange(
      paymentType,
      {
        name: paymentType,
        currentTabIndex: index
      },
      tab
    );
    setCurrentPlan([]);
    setPlanActive(paymentType);
    setActivePlanType(paymentType);
    const disableUnlimitedNewVal =
      paymentType === idConstants.pricingPlanType.payAsYouGo ? true : false;
    pricingPlanPayload.priceSlabs.forEach((row) => {
      row.disableUnlimited = disableUnlimitedNewVal;
    });
  };

  const handlePaymentInfoClick = (
    planName,
    paymentTypeName,
    paymentPrefModeName
  ) => {
    setPlanActive(planName);
    setPaymentTypeActive(paymentTypeName);
    setPaymentModeActive(paymentPrefModeName);
  };

  const setActivePlanType = (planType) => {
    masterData?.plan_type.forEach((types) => {
      if (types.name === planType) {
        if (types?.props && types?.props.length > 0) {
          types.props.forEach((planTypeProps, paymentTypeIndex) => {
            if (paymentTypeIndex === 0) {
              setPaymentTypeActive(planTypeProps.paymentType);
              if (
                planTypeProps?.paymentModePref &&
                planTypeProps?.paymentModePref.length > 0
              ) {
                planTypeProps.paymentModePref.forEach(
                  (paymentModePref, paymentModeIndex) => {
                    if (paymentModeIndex === 0) {
                      setPaymentModeActive(paymentModePref.paymentMode);
                    }
                  }
                );
              }
            }
          });
        }
      }
    });
    setPricingPlanPayload((prevState) => ({
      ...prevState,
      pricingPlanTypeTabValue: planType
    }));
  };

  useEffect(() => {
    setPricingPlanPayload((prevState) => ({
      ...prevState,
      currentPlan: {
        ...prevState.currentPlan,
        planDetails: {
          planType: planActive,
          paymentType: paymentTypeActive,
          paymentModePref: paymentModeActive,
          occurrence: planOccurrence
        }
      }
    }));
  }, [planActive, paymentTypeActive, paymentModeActive, planOccurrence]);

  useEffect(() => {
    const occurrenceDropDownValues = masterData?.plan_type
      ?.find((planType) => planType.name === planActive)
      ?.props?.find((prop) => prop.paymentType === paymentTypeActive)
      ?.['paymentModePref']?.find(
        (paymentModePref) => paymentModePref.paymentMode === paymentModeActive
      )?.occurrence;

    setOccurrenceDropDownValues(occurrenceDropDownValues);
  }, [planActive, paymentTypeActive, paymentModeActive]);

  const handleValueChange = (event, type, tab) => {
    if (event.name == 'pricingType') setSelectedChargeableUnit(event?.value?.name);
    if (type === DynamicFieldConstants.NUMBER) setEnableInputs(event);
    handleFieldChange(type, event, tab);
    if (event.name == 'pricingType') {
      const disableUnlimitedNewVal =
        planActive === idConstants.pricingPlanType.payAsYouGo ? true : false;
      setPricingPlanPayload((prevState) => ({
        ...prevState,
        priceSlabs: [
          {
            ...defaultSlabDetails,
            disableUnlimited: disableUnlimitedNewVal
          }
        ]
      }));
    }
    setKeyName((prevState) => [prevState, event.name]);
    if (type == DynamicFieldConstants.SINGLE_SELECT) {
      if (event.name === 'occurrence') {
        setPlanOccurrence(event?.value?.name);
      }
      if (currentPlan.length === 0) {
        setCurrentPlan([{ name: event.name, value: event.value }]);
      } else if (currentPlan.length > 0) {
        let updatedCurrentPlan = currentPlan.map((currentPlanStateData) => {
          if (currentPlanStateData.name == event.name) {
            return 0;
          }
          return 1;
        });
        let value = [...new Set(keyName.flat(Infinity))];
        if (
          updatedCurrentPlan[updatedCurrentPlan.length - 1] === 1 &&
          value.includes(event.name)
        ) {
          setCurrentPlan((prevState) => [
            ...prevState,
            { name: event.name, value: event.value }
          ]);
        }
      }
    } else if (
      type == DynamicFieldConstants.NUMBER ||
      type == DynamicFieldConstants.TEXT
    ) {
      setCurrentPlan([
        {
          key: event.name,
          value: event.value,
          type: type
        }
      ]);
    }
  };

  const getSingleOptions = (options, tabName, attributeName) => {
    if (attributeName === 'billingCycle') {
      return onBoardingFormData?.billingCycle.map(({ name, type }, index) => ({
        label: name,
        id: index,
        name: type
      }));
    } else if (
      onBoardingFormData?.rateLimitTypes?.length > 0 &&
      attributeName === 'consumptionUnit'
    ) {
      const filteredRateLimitTypes = [...onBoardingFormData?.rateLimitTypes]
        ?.filter((item) => item.type !== 'NO_MEASUREMENT')
        ?.sort((rateLimit1, rateLimit2) =>
          rateLimit1.name.trim().localeCompare(rateLimit2.name.trim())
        );
      const sortedRateLimitTypes = [...onBoardingFormData?.rateLimitTypes]
        ?.filter((item) => item.type === 'NO_MEASUREMENT')
        .concat(filteredRateLimitTypes);

      return (
        tabName === idConstants.pricingPlanType.usageBased
          ? filteredRateLimitTypes
          : sortedRateLimitTypes
      )?.map(({ name, type }, index) => ({
        label: name,
        id: index,
        name: type
      }));
    } else if (attributeName === 'currency' && currencyDropdownOptions?.length > 0) {
      return currencyDropdownOptions;
    } else if (attributeName === 'occurrence') {
      return occurrenceDropDownValues?.map((value, index) => ({
        label:
          value === '1'
            ? idConstants.occurrenceType.oneTime
            : idConstants.occurrenceType.recurring,
        id: index,
        name: value
      }));
    } else {
      return options?.map(({ label, id, value }) => ({
        label: label,
        id: id,
        name: value
      }));
    }
  };

  const handleChangeToggle = (event, tab, type) => {
    handleFieldChange(type, event, tab);
    setEnableInputs(event);
  };

  const getDisabledFunction = (parentType, toggleData) => {
    if (parentType === DynamicFieldConstants.TOGGLE) return false;

    return (
      parentType === DynamicFieldConstants.TOGGLE &&
      pricingPlanPayload?.currentPlan?.rateLimitType !==
        idConstants.pricing.consumptionNoMeasurementType
    );
  };

  const getErrorMessage = (error, parentType, toggleData) => {
    if (
      parentType === DynamicFieldConstants.TOGGLE &&
      getDisabledFunction(parentType, toggleData)
    )
      return '';

    return error;
  };

  const getInputFieldValue = (type, data, tab, netPriceIndex, parentType) => {
    if (
      pricingPlanPayload?.currentPlan?.rateLimitType ===
        idConstants.pricing.consumptionNoMeasurementType &&
      parentType === DynamicFieldConstants.TOGGLE &&
      type === DynamicFieldConstants.NUMBER
    ) {
      if (!enableInputs?.value && currentPlan[netPriceIndex]?.value) {
        currentPlan[netPriceIndex].value = '';
        return '';
      }
    }

    if (
      parentType === DynamicFieldConstants.TOGGLE &&
      type === DynamicFieldConstants.NUMBER
    ) {
      if (
        data?.name === enableInputs?.name &&
        currentPlan[netPriceIndex]?.value >= 0
      )
        return currentPlan[netPriceIndex]?.value;

      if (data?.name !== enableInputs?.name) return data?.value;
    }

    if (
      (parentType === DynamicFieldConstants.OPTION ||
        parentType === DynamicFieldConstants.TOGGLE) &&
      type === DynamicFieldConstants.SINGLE_SELECT
    ) {
      if (data?.name === 'pricingType')
        return getSingleOptions(data?.options, tab.name, data.name);

      return getSingleOptions(data?.options, tab.name, data.name);
    }
    if (data?.value || data?.value === 0) {
      return data?.value;
    }
    return currentPlan[netPriceIndex]?.value;
  };

  const isCheckedFunction = (data) => {
    if (
      pricingPlanPayload?.currentPlan?.rateLimitType ===
        idConstants.pricing.consumptionNoMeasurementType &&
      data?.name === 'addAFixedPrice' &&
      enableInputs?.value
    )
      return true;

    if (
      pricingPlanPayload?.currentPlan?.rateLimitType ===
        idConstants.pricing.consumptionNoMeasurementType &&
      data?.name === 'addAConsumptionBasedPrice'
    )
      return false;

    if (
      pricingPlanPayload?.currentPlan?.rateLimitType !==
        idConstants.pricing.consumptionNoMeasurementType &&
      enableInputs?.name === data?.name &&
      enableInputs?.value
    )
      return true;

    return false;
  };

  const getDropdownValue = (data, tab, parentType) => {
    const value = currentPlan?.dropdown
      ?.map((currentPlanData) => {
        if (currentPlanData.id === data.name) return data.id;
        return null;
      })
      ?.filter((value) => value !== null);

    if (value) {
      return value;
    }

    const dropdownOptions = getInputFieldValue(
      DynamicFieldConstants.SINGLE_SELECT,
      data,
      tab,
      '',
      parentType
    );

    const foundItem = dropdownOptions?.find((item) => item.name === data.value);
    return foundItem;
  };

  const handlePricingPlanOptions = (
    type,
    data,
    parentType,
    tab,
    attributes,
    toggleData
  ) => {
    const consumptionUnitValue =
      Array.isArray(attributes) && attributes?.length > 0
        ? attributes?.find((attr) => attr.name === 'consumptionUnit')?.value
        : '';
    switch (type) {
      case DynamicFieldConstants.TEXT:
        const isDisabled = data.name === 'emailId' ? true : false;
        return (
          toggleData?.name !== 'enableContactSales' &&
          parentType === DynamicFieldConstants.TOGGLE && (
            <TextInput
              displayName={data.displayName}
              placeHolder={data.displayName}
              value={currentPlan?.map(
                (data) => data.type == DynamicFieldConstants.TEXT && data.value
              )}
              name={data.name}
              disabled={isDisabled}
              description={data.description}
              handleChange={(event) =>
                handleValueChange(event, data?.attributeType, tab)
              }
              validation={!isDisabled && JSON.parse(data.validation)}
            />
          )
        );
      case DynamicFieldConstants.NUMBER:
        let netPriceIndex = currentPlan.findIndex(
          (data) => data.type === DynamicFieldConstants.NUMBER
        );
        return (
          <NumberInput
            handleChange={(event) => {
              handleValueChange(event, data?.attributeType, tab);
            }}
            placeHolder={data.displayName}
            value={getInputFieldValue(
              DynamicFieldConstants.NUMBER,
              data,
              tab,
              netPriceIndex,
              parentType
            )}
            name={data.name}
            description={data.description}
            displayName={data.displayName}
            validation={JSON.parse(data.validation)}
            error={getErrorMessage(data.error, parentType, toggleData)}
          />
        );
      case DynamicFieldConstants.TOGGLE:
        return (
          <div className='toggle__div'>
            <div className='toggle__div-container'>
              <div className='toggle__div-subDiv'>
                {data?.displayName && (
                  <p className='subDiv__displayName'>{data.displayName}</p>
                )}
                {data?.description && (
                  <p className='subDiv__description'>{data.description}</p>
                )}
              </div>
              {planActive === idConstants.pricingPlanType.customPlans && (
                <div className='advanced-settings-field-switch'>
                  <ToggleButton
                    label=''
                    name={data.name}
                    checked={isCheckedFunction(data)}
                    disabled={isEnabledFunction(
                      DynamicFieldConstants.TOGGLE,
                      parentType,
                      consumptionUnitValue,
                      planOccurrence,
                      toggleData,
                      data
                    )}
                    handleChange={(event) => handleChangeToggle(event, tab, type)}
                    id={data.name}
                  />
                </div>
              )}
            </div>
            <div className='toggle__div-attributes'>
              {data?.attributes
                ? data?.attributes.map((attributedata) =>
                    handlePricingPlanOptions(
                      attributedata.attributeType,
                      attributedata,
                      type,
                      tab,
                      data.attributes,
                      data
                    )
                  )
                : handlePricingPlanOptions(data.attributeType, data, type, tab)}
            </div>
          </div>
        );
      case DynamicFieldConstants.SINGLE_SELECT:
        return (
          <Dropdown
            displayName={data.displayName}
            options={
              consumptionUnitValue ===
                idConstants.pricing.consumptionNoMeasurementType &&
              data?.name === 'pricingType'
                ? ''
                : getInputFieldValue(
                    DynamicFieldConstants.SINGLE_SELECT,
                    data,
                    tab,
                    '',
                    parentType
                  )
            }
            classname={
              data?.name === 'consumptionQuota' &&
              occurrenceDropDownValues?.length === 1 &&
              occurrenceDropDownValues[0] === '1'
                ? 'select-field hidden'
                : ''
            }
            description={data.description}
            name={data.name}
            value={getDropdownValue(data, tab, parentType)}
            placeHolder={data.placeHolder}
            handleChange={(event) =>
              handleValueChange(event, data?.attributeType, tab)
            }
            validation={JSON.parse(data.validation)}
            disabled={isEnabledFunction(
              DynamicFieldConstants.SINGLE_SELECT,
              parentType,
              consumptionUnitValue,
              planOccurrence,
              toggleData,
              data,
              getDisabledFunction
            )}
          />
        );
    }
  };
  
  const renderPricingPlanOptions = (groupData, item, attributesData) => {
    const attributeType = groupData?.attributes?.length > 0 ? attributesData.attributeType : groupData.attributeType;
    const  attributes = groupData?.attributes?.length > 0 ? attributesData : groupData;
  
    return planActive === item.name && groupData?.attributeType === 'OPTION'
      ? handlePricingPlanOptions(
          attributeType,
          attributes,
          groupData?.attributeType,
          item,
          groupData?.attributes
        )
      : handlePricingPlanOptions(
          groupData.attributeType,
          groupData,
          '',
          item
        );
  };

  const getBasePriceErrorMsg = () => {
    if (isBasePriceLessThanZero()) {
      return (
        <div className='alert-banner new-payments__alert'>
          <p className='success-message'>
            {i18next.t(
              'DYNAMIC_FORM.PRICING_PLAN.BASE_PRICE_SLAB_PRICE_CANNOT_BE_ZERO'
            )}
          </p>
        </div>
      );
    }
  };
  return (
    <div className='pricing-plan__container'>
      <div className='new-payments'>
        <div className='new-payments__nameDiv' style={{ paddingBottom: '0px' }}>
          {tabOptions &&
            tabOptions.map((item, index) => (
              <div
                key={item?.displayName}
                className={`${
                  planActive === item.name
                    ? 'context__tab-background-font-color context__box-style'
                    : 'context__non-selected-tab-style context__box-style'
                }
                ${item?.isDisabled && 'new-payments__disable-tab'}`}
                onClick={() =>
                  handleItemClick(item.name, index, item.isDisabled, item)
                }
              >
                {item.displayName}
              </div>
            ))}
        </div>
        <div
          className='tab_panel'
          style={{ margin: '0px', borderRight: 'none', borderLeft: 'none' }}
        >
          {masterData?.plan_type.map(
            (planType) =>
              planType?.props &&
              planType?.props.length > 0 && (
                <div
                  className={`${planType.name} tab_panel_context`}
                  style={{ display: planType.name === planActive ? 'flex' : 'none' }}
                >
                  {planType?.props.map(
                    (planTypeProps) =>
                      planTypeProps.paymentType !==
                        idConstants.pricingPaymentType.notApplicable && (
                        <div
                          className={
                            planTypeProps.paymentType === paymentTypeActive &&
                            planType.name === planActive
                              ? `context__tab-background-font-color context__box-style`
                              : `context__non-selected-tab-style context__box-style`
                          }
                          style={{ textTransform: 'capitalize' }}
                          onClick={() =>
                            handlePaymentInfoClick(
                              planType.name,
                              planTypeProps.paymentType,
                              planTypeProps.paymentModePref[0].paymentMode
                            )
                          }
                        >
                          {planTypeProps.paymentType}&nbsp;
                          {planTypeProps.paymentModePref.length === 1 &&
                            planTypeProps.paymentModePref[0].paymentMode !==
                              idConstants.pricingPaymentType.notApplicable && (
                              <span>
                                {' (' +
                                  planTypeProps.paymentModePref[0].paymentMode +
                                  ')'}
                              </span>
                            )}
                        </div>
                      )
                  )}
                </div>
              )
          )}
        </div>
        <div
          className='tab_panel'
          style={{ margin: '0px', borderRight: 'none', borderLeft: 'none' }}
        >
          {masterData?.plan_type.map(
            (planType) =>
              planType?.props &&
              planType?.props.length > 0 && (
                <div
                  key={planType?.id}
                  className={`${planType.value} tab_panel_context`}
                  style={{ display: planType.name === planActive ? 'flex' : 'none' }}
                >
                  {planType?.props.map(
                    (planTypeProps) =>
                      planTypeProps.paymentType !==
                        idConstants.pricingPaymentType.notApplicable &&
                      planTypeProps.paymentModePref.length > 1 &&
                      planTypeProps.paymentModePref.map((paymentModePref) => (
                        <div
                          key={paymentModePref?.paymentMode}
                          className={
                            planTypeProps.paymentType === paymentTypeActive &&
                            planType.name === planActive &&
                            paymentModePref.paymentMode === paymentModeActive
                              ? `context__tab-background-font-color context__box-style`
                              : `context__non-selected-tab-style context__box-style`
                          }
                          style={{
                            textTransform: 'capitalize',
                            display:
                              planType.name === planActive &&
                              planTypeProps.paymentType === paymentTypeActive
                                ? 'flex'
                                : 'none'
                          }}
                          onClick={() =>
                            handlePaymentInfoClick(
                              planType.name,
                              planTypeProps.paymentType,
                              paymentModePref.paymentMode
                            )
                          }
                        >
                          {paymentModePref.paymentMode}
                        </div>
                      ))
                  )}
                </div>
              )
          )}
        </div>

        {tabOptions &&
          tabOptions.map(
            (item, index) =>
              planActive === item.name && (
                <div className='new-payments__wholeDiv'>
                  {item.placeHolder && (
                    <div className='new-payments__wholeDiv-iconWrapper'>
                      <i className='a-icon boschicon-bosch-ic-alert-info'></i>
                      <p className='payments__recurring-payment-warning'>
                        <span className='payments__recurring-payment-warning-heading'>
                          {item.displayName} {' - '}
                        </span>
                        <span>{item.placeHolder}</span>
                      </p>
                    </div>
                  )}
                  {(item?.groups?.length > 0 ? item.groups : item.attributes).map(
                    (groupData) => (
                      <div
                        className='new-payments__groupDiv'
                        key={groupData?.displayName}
                      >
                        {planActive === item.name &&
                          groupData?.name !== 'enableContactSales' && (
                            <p className='heading-style -size-m highlight mb_1'>
                              {groupData.displayName}
                            </p>
                          )}
                        <div className='new-payments__groupDiv-wrapper'>
                          {groupData?.attributes?.length > 0
                            ? groupData.attributes.map((attributesData) => (
                                <>
                                  {attributesData &&
                                    renderPricingPlanOptions(
                                      groupData,
                                      item,
                                      attributesData
                                    )}
                                </>
                              ))
                            : handlePricingPlanOptions(
                                groupData.attributeType,
                                groupData,
                                '',
                                item
                              )}
                        </div>
                      </div>
                    )
                  )}
                  {item?.name === idConstants.pricingPlanType.usageBased && (
                    <PricingSlabsTable
                      rateLimits={masterData?.rate_limit}
                      dataBandwidthChargableUnits={masterData?.bandwidth}
                      quotas={masterData?.rate_limit_time_unit}
                      currencies={masterData?.currency}
                      chargableUnitMasterData={masterData?.chargeable_unit}
                      currency={pricingPlanPayload.currentPlan.currencyCode}
                      rateLimit={pricingPlanPayload.currentPlan.rateLimitType}
                      chargeableUnit={selectedChargeableUnit}
                      quota={pricingPlanPayload.currentPlan.quota}
                      slabLimitDetails={pricingPlanPayload.priceSlabs}
                      setPricingSlabs={setPricingSlabs}
                      setTableValidation={setTableValidation}
                      getBasePriceErrorMsg={getBasePriceErrorMsg}
                    />
                  )}
                  {pricingPlanPayload.currentPlan.planGroupName ===
                    PRICING.PLAN_GROUP_TYPE.PRIVATE.VALUE && (
                    <div className='new-payments__wholeDiv-iconWrapper mps-warning'>
                      <i className='a-icon boschicon-bosch-ic-alert-info'></i>
                      <p className='payments__recurring-payment-warning'>
                        {i18next.t(
                          'DYNAMIC_FORM.PRICING_PLAN.PLAN_GROUP_NAME.PRIVATE.INFO'
                        )}
                      </p>
                    </div>
                  )}
                </div>
              )
          )}
      </div>
      <div>{children}</div>
    </div>
  );
};

export default PaymentOptions;
