import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';
import _ from 'lodash';

import { ParseDynamicOnboardingForm } from '../../shared/api-parser';
import { basicInfoParser } from '../../shared/payload-parser';
import FormContainer from '../../containers/form-container';
import Loader from '../../components/Shared/Utils/Loader';
import ToastMessages from '../../data/toastMessages';
import loading from '../../actions/LoaderAction';
import {
  getApiPlans,
  deletePricingPlan,
  createPricingPlan,
  updatePricingStatus,
  handleShowMessage
} from '../../actions/api/pricing-plan-action';
import BaseLayout from '../../msreact-kit/html-components/base-layout';
import {
  saveFormBasicInfo,
  saveFormJsonData,
  updateProductStatus,
  publishProduct,
  saveFormAdditionalInfo,
  updateFormBasicInfo,
  getDynamicFormData,
  uploadFile,
  deleteFile,
  updateFormAdditionalInfo,
  getProductDocsListById,
  validateProductUniqueness,
  handleResetForm,
  getProductAllDocsList,
  saveBasicInfo,
  saveMinimumOrderQuantity,
  handleSaveTermsAndCondition,
  saveAdditionalInfo,
  handleSaveDPNDocument,
  publishToTenant,
  saveFormJsonDataOnlyValue
} from '../../actions/api/hardwareAction';
import { logout, checkIfReseller } from '../../actions/LoginAction';
import { getProductDetailedInfo } from '../../actions/api/SolutionAction';
import {
  stockAvailApi,
  getProductQuantity
} from '../../actions/api/stockAvailAction';
import {
  getProductVisibilityData,
  postProductVisibilityData
} from '../../actions/ProductDetailAction';
import { getTenantCategoryMapping } from '../../actions/tenant-service-action';
import {
  IS_MKOSINT_TENANT,
  BUYER_TENANT_ID,
  DCS_SUBSYSTEM_PRODUCT_ONBOARDING_TOGGLE
} from '../../actions/configuration';
import { cloneFunction, getNestedVal } from '../../shared/api-parser/clone-parser';
import Snackbar from '../../components/Shared/Utils/SnackBar';
import { trackEvent } from '../../utils/analytics';
import { getRegionCode } from '../../utils/get-region-details';
import isEmpty from '../../validator/isEmpty';
import joinWithUnderscore from '../../utils/join-with-underscore';
import useHandleLoader from '../../msreact-kit/hooks/useHandleLoader';
import { alertSeverity } from '../../data/appConstants';
import idConstants from '../../data/idConstants';
import { GAConstants, GAEventAction } from '../../data/ga-constants';
import { FieldConstants } from '../../shared/constants/field-constants';
import { DynamicFieldConstants } from '../../shared/constants';
import { getPricingPlanMasterData } from '../../actions/MasterDataAction';
import { retrieveUserCompanyStatus } from '../../actions/CompanyAction';
import useDebounce from '../../msreact-kit/hooks/useDebounce';
import {
  getDraftedPricingPlan,
  isShowPricingPlanNotification
} from '../../shared/shared-function/services';
import { cloneFunctionOnlyValue } from '../../shared/api-parser/product-value-parser';
import { emptyTextObj } from '../../components/Shared/RichTextEditor';
import InfoModal from '../../components/Shared/Utils/Modal/InformationModal';

import './index.scss';

const ProductOnboarding = () => {
  const regionCode = getRegionCode().toLowerCase();
  const [activeCategory, setActiveCategory] = useState(0);
  const [activeGrouping, setActiveGrouping] = useState(0);
  const [showSuccessBoxModal, setShowSuccessBoxModal] = useState(false);
  const [displaySnackBar, setDisplaySnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [initialFormData, setInitialFormData] = useState({});
  const [initialFormDataOnlyValue, setInitialFormDataOnlyValue] = useState({});
  const [availableStock, setAvailableStock] = useState(null);
  const [hsnData, setHsnData] = useState(null);
  const [infoModal, setInfoModal] = useState({
    openInfoModal: false,
    modalHeading: '',
    modalDescription: '',
    primaryLabel: '',
    secondaryLabel: '',
    type: '',
    data: {}
  });
  const [productDocuments, setProductDocuments] = useState(null);
  const [initialCheckResellerCall, setInitialCheckResellerCall] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();
  const categoryId = location?.state?.productCategoryId;
  const draftStatusId = idConstants.drafted.statusId;

  const {
    loader,
    onBoardingFormData,
    onBoardingFormDataOnlyValue,
    prodBasicInfo,
    prodNameError,
    pricingPlans,
    termsAndConditionFile,
    prodDetailedInfo,
    isVisibleData,
    dpnDocument,
    userCompanyStatus,
    isReseller,
    tenantCategoryMapping
  } = useSelector((state) => {
    return {
      loader: state.LoaderReducer.loader,
      onBoardingFormData: state.FormReducer.formJsonData,
      onBoardingFormDataOnlyValue: state.FormReducer.formJsonDataOnlyValue,
      prodBasicInfo: state.FormReducer.prodBasicInfo,
      prodNameError: state.SolutionReducer.prodNameError,
      pricingPlans: state.ProductPricingPlanReducer.pricingPlans,
      termsAndConditionFile: state.FormReducer.termsAndConditionFile,
      prodDetailedInfo: state.FormReducer.prodDetailedInfo,
      isVisibleData: location?.state?.isClone
        ? true
        : state.ProductDetailReducer.isVisibleData,
      dpnDocument: state.FormReducer.dpnDocument,
      userCompanyStatus: state.CompanyDetailsReducer.userCompanyStatus,
      isReseller: state.loginReducer.isReseller,
      tenantCategoryMapping: state.TenantServiceReducer.tenantCategoryMapping
    };
  });

  const debouncedAvailableStock = useDebounce(availableStock);
  const debouncedHsnCode = useDebounce(hsnData?.value, 500);

  const [handleLoader] = useHandleLoader();

  useEffect(() => {
    handleLoader();
  }, []);

  useEffect(() => {
    if (availableStock) {
      handleUpdateStock();
    }
  }, [debouncedAvailableStock]);

  useEffect(() => {
    if (hsnData && hsnData?.value?.toString()?.length >= hsnData?.min) {
      handleUpdateHsnCode();
    }
  }, [debouncedHsnCode]);

  useEffect(() => {
    if (tenantCategoryMapping !== null) return;
    dispatch(getTenantCategoryMapping());
  }, [tenantCategoryMapping]);

  useEffect(() => {
    dispatch(handleResetForm());
    dispatch(retrieveUserCompanyStatus());
    const formRes = dispatch(getDynamicFormData(categoryId));
    if (location.state?.productId) {
      dispatch(getProductVisibilityData(location.state?.productId));
    }
    dispatch(getPricingPlanMasterData(categoryId));
    formRes.then((result) => {
      let res = ParseDynamicOnboardingForm(result);
      dispatch(saveFormJsonData(res));
      if (location?.state?.isEdit) {
        dispatch(loading(true));
        getPricingPlan();
        const data = Promise.all([
          dispatch(getProductDetailedInfo(location.state.productId, categoryId)),
          dispatch(getProductAllDocsList(location.state.productId)),
          dispatch(getProductQuantity(location.state.productId))
        ]);
        data.then((resp) => {
          const [additionalInfo, documents, quantity] = resp;
          const cloneData = cloneFunction(res, additionalInfo, documents, quantity);
          const cloneOnlyValue = _.cloneDeep(
            cloneFunctionOnlyValue(res, additionalInfo, documents, quantity)
          );
          setProductDocuments(documents);
          dispatch(saveFormJsonData(cloneData));
          dispatch(saveFormJsonDataOnlyValue(cloneOnlyValue));
          dispatch(saveBasicInfo(additionalInfo.prodBasicInfo));
          setInitialFormData(cloneData);
          setInitialFormDataOnlyValue(cloneOnlyValue);
          dispatch(loading(false));
          dispatch(saveAdditionalInfo(additionalInfo.productDetailedInfo));
        });
      }
      if (location?.state?.isClone) {
        const data = dispatch(
          getProductDetailedInfo(location.state.productId, categoryId)
        );
        data.then((resp) => {
          const additionalInfo = resp;
          const cloneData = cloneFunction(res, additionalInfo, [], {}, true);
          dispatch(saveFormJsonData(cloneData));
        });
      }
      if (location?.state?.isSource) {
        const additionalInfo = location.state.data ?? {};
        if (!isEmpty(additionalInfo)) {
          const cloneData = cloneFunction(res, additionalInfo, [], {}, true);
          dispatch(saveFormJsonData(cloneData));
        }
      }
    });
  }, []);

  useEffect(() => {
    if (
      isEmpty(userCompanyStatus?.companyId) ||
      userCompanyStatus?.companyRegisteredStatus !== 'approved'
    )
      return;

    if (
      DCS_SUBSYSTEM_PRODUCT_ONBOARDING_TOGGLE === 'true' &&
      initialCheckResellerCall &&
      isEmpty(location.state.productId)
    ) {
      dispatch(checkIfReseller(userCompanyStatus.companyId));
      setInitialCheckResellerCall(false);
    }
  }, [userCompanyStatus]);

  useEffect(() => {
    if (prodNameError && prodNameError.error) {
      const listData = JSON.parse(JSON.stringify(onBoardingFormData));
      const findValIndex = listData.steps[activeCategory]['attributes'].findIndex(
        (item) => item.name === FieldConstants.productName
      );
      listData.steps[activeCategory]['attributes'][findValIndex]['error'] =
        prodNameError.message;
      dispatch(saveFormJsonData(listData));
    }
  }, [prodNameError]);

  const getPricingPlan = () => {
    dispatch(
      getApiPlans(
        location.state.productId,
        location?.state?.productName,
        categoryId,
        GAConstants.isTriggerGAEvent
      )
    );
  };

  const signOut = () => {
    dispatch(logout());
  };

  const handleCheckValuesChanged = () => {
    return (
      prodBasicInfo?.status?.statusId !== idConstants.drafted.statusId &&
      _.isEqual(initialFormDataOnlyValue, onBoardingFormDataOnlyValue)
    );
  };

  const handleDeletePlan = (value) => {
    dispatch(
      deletePricingPlan(
        value,
        value.itemId,
        categoryId,
        prodBasicInfo?.status?.description,
        prodBasicInfo?.companyNo
      )
    ).then((response) => {
      if (response) {
        showSuccessSnackBar(
          i18next.t(
            'DYNAMIC_FORM.SHARED.SUCCESS_MESSAGE.PRICING_PLAN_DELETED_SUCCESSFULLY'
          )
        );
      }
    });
  };

  const getSuccessUploadMessage = (docTypeId) => {
    switch (docTypeId) {
      case idConstants.documentType.thumbnailImg:
        return ToastMessages.thumbnailUpload.msg;
      case idConstants.documentType.videoFile:
        return ToastMessages.videoUpload.msg;
      case idConstants.documentType.mutlimediaImageFile:
        return ToastMessages.multimediaImageUpload.msg;
      case idConstants.documentType.highlightsDocument:
        return ToastMessages.highlightImageUpload.msg;
      case idConstants.documentType.caseStudyImageFile:
        return ToastMessages.caseStudyImageUpload.msg;
      default:
        return ToastMessages.documentUpload.msg;
    }
  };

  const handleUploadFile = async (imageFile, value, type) => {
    let uploadFileRes;
    const attributeId =
      value.attributeType === DynamicFieldConstants.TEXT_AND_FILE
        ? value.attributes[0].attributeId
        : value.attributeId;
    for (let index = 0; index < imageFile?.value?.length; index++) {
      const element = imageFile?.value[index];
      uploadFileRes = await dispatch(
        uploadFile(element, prodBasicInfo.productId, attributeId, categoryId)
      );
      if (uploadFileRes)
        showSuccessSnackBar(
          getSuccessUploadMessage(uploadFileRes[0].documentTypeId)
        );
    }
    dispatch(getProductDocsListById(prodBasicInfo.productId, attributeId)).then(
      (data) => {
        if (data) {
          const xpathValues = value?.xpath?.split('/');
          const valuesToCheck = [
            FieldConstants.productHighlights,
            FieldConstants.caseStudySection,
            FieldConstants.caseStudy
          ];
          const isProductHighlights = valuesToCheck.some((value) =>
            xpathValues?.includes(value)
          );
          let filteredData = [];
          filteredData = data?.filter((item) => item.uid);
          const uploadFileData = { ...imageFile };
          if (filteredData && filteredData?.length > 0) {
            if (isProductHighlights) {
              if (uploadFileRes) {
                const prodHighlightsImg = filteredData.filter(
                  (item) => item.documentName === uploadFileData.value[0].name
                );
                uploadFileData.value = prodHighlightsImg;
                uploadFileData.productDocMetaInfoId =
                  prodHighlightsImg[0].productDocMetaInfoId;
              } else {
                uploadFileData.value = [];
              }
            } else {
              uploadFileData.value = filteredData;
            }
          } else {
            uploadFileData.value = [];
          }

          delete uploadFileData.type;
          handleChange(uploadFileData, value);
        }
      }
    );

    if (type === 'addDoc') {
      // Call state change API to move the product to draft
      dispatch(
        updateProductStatus(
          draftStatusId,
          prodBasicInfo.productId,
          categoryId,
          userCompanyStatus?.companyId
        )
      );
    }
  };

  const handleNextCategory = (fieldData, sectionLength, data) => {
    // If no attribute change go to next section/page without calling an API
    const isLastStep = handleCheckLastStep(data);
    if (handleCheckValuesChanged() && !isLastStep) {
      return handleNextStep(sectionLength);
    }

    if (data.name === FieldConstants.prodBasicInfo) {
      handleBasicInfo(fieldData, sectionLength);
    } else {
      handleAdditionalInfo(fieldData, sectionLength, data);
      if (fieldData?.name === FieldConstants.pricingPlan) {
        //GA-309
        triggerGAEvent(fieldData);
      }
    }
  };

  const handleNextStep = (sectionLength) => {
    if (sectionLength > 0 && sectionLength - 1 !== activeGrouping) {
      setActiveGrouping((prevState) => prevState + 1);
      return;
    }
    setActiveCategory((prevState) => prevState + 1);
    setActiveGrouping(0);
  };

  const handlePreviousCategory = (data, sectionLength) => {
    if (sectionLength > 0 && activeGrouping !== 0) {
      setActiveGrouping((prevState) => prevState - 1);
      return;
    }

    setActiveCategory((prevState) => prevState - 1);
  };

  const handleSuccessDialogBox = () => {
    setShowSuccessBoxModal((prev) => !prev);
  };

  const handleUpdateBasicInfo = (reqObject, prodId, isTermsFileUploaded) => {
    const reqObj = {};
    const prodBasicInfo = {};
    const productCategory = {};
    productCategory.categoryId = categoryId;
    prodBasicInfo.productCategory = { ...productCategory };
    reqObj.productDetailedInfo = {
      productLongDesc: reqObject.prodBasicInfo.productLongDesc,
      productShortDesc: reqObject?.prodBasicInfo?.productShortDesc,
      autoProductUrl: reqObject?.prodBasicInfo?.autoProductUrl
    };

    if (reqObject.prodBasicInfo.termsAndConditions) {
      reqObj.productDetailedInfo.termsAndConditions =
        reqObject.prodBasicInfo.termsAndConditions;
    }

    if (isTermsFileUploaded) {
      reqObj.productDetailedInfo.termsAndConditions = JSON.stringify(emptyTextObj());
    }

    if (reqObject.prodBasicInfo.subcategory) {
      reqObj.productDetailedInfo.subcategory = reqObject.prodBasicInfo.subcategory;
    }
    reqObj.prodBasicInfo = { ...prodBasicInfo };
    if (
      categoryId !== idConstants.automotives.categoryId &&
      !prodDetailedInfo?.productLongDesc &&
      !prodDetailedInfo?.termsAndConditions
    ) {
      reqObj.productDetailedInfo = {
        ...reqObj.productDetailedInfo,
        productRegion: regionCode,
        isReseller: isReseller
      };
      dispatch(saveFormAdditionalInfo(reqObj, prodId, userCompanyStatus?.companyId));
    } else {
      reqObj.productDetailedInfo = changeReqBody(reqObj.productDetailedInfo);
      dispatch(updateFormAdditionalInfo(reqObj, prodId));
    }

    //GA-402
    trackEvent(
      'edit_product_basic_info',
      GAEventAction.added,
      joinWithUnderscore(location?.state?.productName?.toLowerCase())
    );
  };

  const handleUpdateTermsAndCondition = (file) => {
    dispatch(handleSaveTermsAndCondition(file));
  };

  const handleUpdateDPNDocument = (file) => {
    dispatch(handleSaveDPNDocument(file));
  };

  const handleBasicInfo = (fieldData, sectionLength, isPreventNextStep) => {
    let isTermsFileUploaded = false;
    const reqObject = basicInfoParser(fieldData, categoryId);
    let reqBasicObj = JSON.parse(JSON.stringify(reqObject));
    delete reqBasicObj.prodBasicInfo.productLongDesc;
    delete reqBasicObj.prodBasicInfo.termsAndConditions;
    const documentTypeId = dpnDocument?.[0]?.documentTypeId;
    if (reqBasicObj.prodBasicInfo.subcategory)
      delete reqBasicObj.prodBasicInfo.subcategory;
    if (reqBasicObj.prodBasicInfo.tenant) delete reqBasicObj.prodBasicInfo.tenant;
    let res = {};
    if (prodBasicInfo.productId) {
      reqBasicObj.prodBasicInfo.productId = prodBasicInfo.productId;
      delete reqBasicObj.prodBasicInfo.productName;
      res = dispatch(updateFormBasicInfo(reqBasicObj, prodBasicInfo.productId));
      if (dpnDocument?.length > 0 && dpnDocument[0].type) {
        dispatch(
          uploadFile(
            dpnDocument[0],
            prodBasicInfo.productId,
            documentTypeId,
            categoryId
          )
        ).then((docRes) => {
          handleUpdateDPNDocument(docRes);
        });
      }
      res.then(() => {
        setInitialFormDataOnlyValue(onBoardingFormDataOnlyValue);
        !isPreventNextStep && handleNextStep(sectionLength);
        handleUpdateBasicInfo(
          reqObject,
          prodBasicInfo.productId,
          isTermsFileUploaded
        );
      });
    } else {
      res = dispatch(saveFormBasicInfo(reqBasicObj, userCompanyStatus?.companyId));
      res.then((resp) => {
        const reqObj = {};
        const prodBasicInfo = {};
        const productCategory = {};
        productCategory.categoryId = categoryId;
        prodBasicInfo.productCategory = { ...productCategory };
        reqObj.productDetailedInfo = {
          productLongDesc: reqObject.prodBasicInfo.productLongDesc,
          productShortDesc: reqObject?.prodBasicInfo?.productShortDesc,
          autoProductUrl: reqObject?.prodBasicInfo?.autoProductUrl,
          termsAndConditions: reqObject.prodBasicInfo.termsAndConditions,
          productRegion: regionCode,
          isReseller: isReseller
        };
        if (termsAndConditionFile?.length > 0 && termsAndConditionFile[0].type) {
          reqObj.productDetailedInfo.termsAndConditions =
            JSON.stringify(emptyTextObj());
        }
        if (reqObject.prodBasicInfo.tenant) {
          const tenant = [
            {
              key: reqObject.prodBasicInfo.tenant,
              value: reqObject.prodBasicInfo.tenant,
              id: reqObject.prodBasicInfo.tenant
            }
          ];
          reqObj.productDetailedInfo.tenant = tenant;
        }
        if (reqObject.prodBasicInfo.subcategory) {
          reqObj.productDetailedInfo.subcategory =
            reqObject.prodBasicInfo.subcategory;
        }
        reqObj.prodBasicInfo = { ...prodBasicInfo };
        reqObj.productDetailedInfo = changeReqBody(reqObj.productDetailedInfo);
        dispatch(
          saveFormAdditionalInfo(
            reqObj,
            resp.productId,
            userCompanyStatus?.companyId
          )
        );
        dispatch(
          updateProductStatus(
            idConstants.drafted.statusId,
            resp.productId,
            categoryId,
            userCompanyStatus?.companyId
          )
        );

        const promiseArr = [];
        if (termsAndConditionFile?.length > 0 && termsAndConditionFile[0].type) {
          promiseArr.push(
            dispatch(
              uploadFile(
                termsAndConditionFile[0],
                resp.productId,
                idConstants.documentType.termsAndConditionsDocument,
                categoryId
              )
            )
          );
        }
        if (dpnDocument?.length > 0 && dpnDocument[0].type) {
          promiseArr.push(
            dispatch(
              uploadFile(dpnDocument[0], resp.productId, documentTypeId, categoryId)
            )
          );
        }
        if (promiseArr?.length > 0) {
          const data = Promise.all(promiseArr);
          data.then((promiseResponse) => {
            promiseResponse.forEach((response1) => {
              response1?.forEach((response2) => {
                if (
                  response2.documentTypeId ===
                  idConstants.documentType.termsAndConditionsDocument
                ) {
                  handleUpdateTermsAndCondition(response2);
                }
                if (
                  response2.documentTypeId ===
                    idConstants.documentType.dpnDocument ||
                  response2.documentTypeId ===
                    idConstants.documentType.statementOfWork
                ) {
                  handleUpdateDPNDocument(response2);
                }
              });
            });

            !isPreventNextStep && handleNextStep(sectionLength);
          });
        } else {
          !isPreventNextStep && handleNextStep(sectionLength);
        }

        //GA-302
        trackEvent(
          'create_product_basic_info',
          GAEventAction.added,
          joinWithUnderscore(reqObject?.prodBasicInfo?.productName?.toLowerCase())
        );
      });
    }
  };

  const handleCheckLastStep = (data) => {
    let isLastStep = false;
    if (
      (activeCategory === onBoardingFormData.steps.length - 1 &&
        data?.sections?.length === 0) ||
      (activeCategory === onBoardingFormData.steps.length - 1 &&
        data?.sections?.length > 0 &&
        activeGrouping === data.sections.length - 1)
    ) {
      isLastStep = true;
    }
    return isLastStep;
  };

  const handleSnackBar = () => {
    setDisplaySnackBar((prev) => !prev);
  };

  const isError = (attributes) => {
    return attributes.some((attribute) => attribute?.error?.length > 0);
  };

  const handleAutoSave = (fieldData, data, isDisableBtnCallback, ifClickEvent) => {
    const isDisable = isDisableBtnCallback(fieldData);
    if (
      data.name === FieldConstants.prodBasicInfo &&
      !isError(fieldData.attributes) &&
      !isDisable
    ) {
      handleBasicInfo(fieldData, 0, true);
      showSuccessSnackBar(
        i18next.t('DYNAMIC_FORM.SNACKBAR.YOUR_CHANGES_ARE_AUTOSAVE')
      );
    } else {
      if (
        data.name !== FieldConstants.prodBasicInfo &&
        prodBasicInfo.productId &&
        !isError(fieldData.attributes)
      ) {
        const reqObject = basicInfoParser(fieldData, categoryId, prodDetailedInfo);
        reqObject.productDetailedInfo = changeReqBody(reqObject.productDetailedInfo);
        const response = dispatch(
          updateFormAdditionalInfo(reqObject, prodBasicInfo.productId)
        );
        response.then(() => {
          if (!!ifClickEvent) {
            showSuccessSnackBar(
              i18next.t('DYNAMIC_FORM.SNACKBAR.PRODUCT_DETAILS_ARE_SAVED')
            );
          } else {
            showSuccessSnackBar(
              i18next.t('DYNAMIC_FORM.SNACKBAR.YOUR_CHANGES_ARE_AUTOSAVE')
            );
          }
        });
      }
    }
  };

  const showSuccessSnackBar = (message) => {
    handleSnackBar();
    setSnackBarMessage(message);
    setSeverity(alertSeverity.SUCCESS);
  };

  const triggerGAEvent = (fieldData) => {
    const values = [];
    let fields = [];
    let gaEvent = GAEventAction.added;

    const findValIndex = onBoardingFormData.steps[0]['attributes'].findIndex(
      (item) => item.name === FieldConstants.productName
    );
    const productName =
      onBoardingFormData.steps[0]['attributes'][findValIndex]['value'];

    if (fieldData.groups?.length > 0) {
      fieldData.groups.forEach((element) => {
        fields = fields.concat(element.attributes);
        const obj = {};
        element.attributes.forEach((element) => {
          if (
            element.attributeType === DynamicFieldConstants.TEXT ||
            element.attributeType === DynamicFieldConstants.TEXT_AREA
          ) {
            if (element.value?.length > 0) {
              obj[element.name] = element.value;
            }
          }
        });
        if (Object.values(obj).length > 0) {
          values.push(obj);
        }
      });
    } else {
      fields = fields.concat(fieldData.attributes);
      fields.forEach((element) => {
        const obj = {};
        if (element?.value?.length > 0) {
          obj[element.name] = element.value;
          values.push(obj);
        }
      });
    }
    if (
      fieldData?.name === FieldConstants.media ||
      fieldData?.name === FieldConstants.documents
    ) {
      gaEvent = GAEventAction.uploaded;
    }
    if (values && values?.length > 0) {
      trackEvent(
        location?.state?.isEdit
          ? `edit_product_${joinWithUnderscore(fieldData.displayName.toLowerCase())}`
          : `create_product_${joinWithUnderscore(
              fieldData.displayName.toLowerCase()
            )}`,
        gaEvent,
        location?.state?.isEdit && location?.state?.productName
          ? joinWithUnderscore(location?.state?.productName?.toLowerCase())
          : joinWithUnderscore(productName.toLowerCase())
      );
    }
    if (fieldData?.name === FieldConstants.pricingPlan) {
      if (onBoardingFormData?.isQuotation) {
        trackEvent(
          location?.state?.isEdit
            ? 'edit_product_request_for_quotation'
            : 'create_product_request_for_quotation',
          GAEventAction.clicked,
          joinWithUnderscore(productName.toLowerCase())
        );
      } else {
        trackEvent(
          location?.state?.isEdit
            ? 'edit_product_pricing_plan'
            : 'create_product_pricing_plan',
          GAEventAction.clicked,
          joinWithUnderscore(productName.toLowerCase())
        );
      }
    }
  };

  const modifyCountryValue = (countryValue, key) => {
    const value = {
      key: countryValue.id.toString(),
      value: countryValue.label ?? countryValue.value,
      id: countryValue.id.toString()
    };
    if (key === FieldConstants.countryOfUsage) {
      return {
        ...value,
        _id: countryValue._id?.toString() ?? countryValue.id.toString()
      };
    }
    return value;
  };

  const changeCountryField = (reqObject) => {
    for (const key in reqObject) {
      if (reqObject.hasOwnProperty(key)) {
        if (key === FieldConstants.countryOfUsage && Array.isArray(reqObject[key])) {
          const modifiedValue = [];
          reqObject[key].forEach((element) => {
            element = modifyCountryValue(element, key);
            modifiedValue.push(element);
          });
          reqObject[key] = modifiedValue;
        } else if (key === FieldConstants.countryOfOrigin) {
          reqObject[key] = modifyCountryValue(reqObject[key]);
        } else if (reqObject[key] && typeof reqObject[key] === 'object') {
          changeCountryField(reqObject[key]);
        }
      }
    }
  };

  const changeReqBody = (productDetailedInfo) => {
    changeCountryField(productDetailedInfo);
    return productDetailedInfo;
  };

  const handleAdditionalInfo = (fieldData, sectionLength, data) => {
    const reqObject = basicInfoParser(fieldData, categoryId, prodDetailedInfo);

    const isLastStep = handleCheckLastStep(data);
    const isValuesChanged = handleCheckValuesChanged();

    if (isLastStep) {
      handleSubmitProduct(reqObject, fieldData);
    } else {
      let res;
      if (fieldData.name !== FieldConstants.pricingPlan) {
        reqObject.productDetailedInfo = changeReqBody(reqObject.productDetailedInfo);
        res = dispatch(updateFormAdditionalInfo(reqObject, prodBasicInfo.productId));
        if (!isValuesChanged) {
          dispatch(
            updateProductStatus(
              idConstants.drafted.statusId,
              prodBasicInfo.productId,
              categoryId,
              userCompanyStatus?.companyId
            )
          );
        }
      }
      if (res) {
        res.then((productDetailedInfoData) => {
          const productDetailedInfo = {
            productDetailedInfo: productDetailedInfoData
          };
          handleGroupElementId(fieldData, productDetailedInfo);
          handleNextStep(sectionLength);
          //GA-The below GAevent for the all dyanmic product onboarding form for both edit & create.
          triggerGAEvent(fieldData);
        });
      } else {
        handleNextStep(sectionLength);
      }
    }
  };

  const handleUpdateStock = () => {
    const payload = {
      productId: prodBasicInfo.productId,
      quantity: availableStock,
      type: 'REFURBISH',
      orderId: null
    };
    dispatch(stockAvailApi(payload));
  };

  const handleUpdateHsnCode = () => {
    const pricingData =
      onBoardingFormData?.steps?.length > 0 &&
      onBoardingFormData.steps[activeCategory];
    const reqObject = basicInfoParser(pricingData, categoryId, prodDetailedInfo);

    const value = [];
    reqObject.prodBasicInfo.saccode = hsnData?.value?.toString();
    prodBasicInfo.productBusinessCategoryMap.forEach(function (arrObj) {
      value.push({
        businessCategory: {
          businessCategoryId: arrObj.businessCategory.businessCategoryId
        }
      });
    });
    reqObject.prodBasicInfo.productBusinessCategoryMap = value;
    delete reqObject.productDetailedInfo;
    dispatch(updateFormBasicInfo(reqObject, prodBasicInfo.productId));
  };

  const handleMOQ = (value) => {
    const payload = {
      productId: prodBasicInfo.productId,
      minimumOrderQuantity: Number(value)
    };
    dispatch(saveMinimumOrderQuantity(payload));
  };

  const blurValue = (event) => {
    if (!event?.value || !event.value.toString() || event.error) return;

    switch (event.name) {
      case FieldConstants.productName:
        checkProductUniqueness(event.value);
        break;
      case FieldConstants.minimumOrderQuantity:
        handleMOQ(event.value);
        break;
      case FieldConstants.stock:
        setAvailableStock(event.value);
        break;
    }
  };

  const checkProductUniqueness = (name) => {
    if (name && name.trim().length > 0) {
      dispatch(validateProductUniqueness(name, categoryId));
    } else {
      const listData = JSON.parse(JSON.stringify(onBoardingFormData));
      const findValIndex = listData.steps[activeCategory]['attributes'].findIndex(
        (item) => item.name === FieldConstants.productName
      );
      listData.steps[activeCategory]['attributes'][findValIndex]['value'] = '';
      listData.steps[activeCategory]['attributes'][findValIndex]['error'] =
        i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.INVALID_PRODUCT_NAME');
      dispatch(saveFormJsonData(listData));
    }
  };

  const handleChange = (event, values) => {
    if (event.name === 'technologies') {
      const isOthers = event.value.find(
        (tehcnologyVal) => tehcnologyVal?.value === 'Others'
      );
      onBoardingFormData?.steps?.forEach((step) => {
        if (step.displayName === 'Product Details') {
          step?.sections?.forEach((section) => {
            if (section?.name === 'genericInformation') {
              section?.attributes?.forEach((attributes) => {
                if (attributes?.name === 'others') {
                  attributes.disabled = isOthers ? false : true;
                }
              });
            }
          });
        }
      });
    }
    if (values.name === 'termsAndConditions') {
      values['validation']['rule']['required']['isRequired'] = true;
      values.validation.rule.required.errorMessage = i18next.t(
        'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.TERMS_AND_CONDITIONS_IS_MANADATORY'
      );
    }
    const listData = _.cloneDeep(onBoardingFormData);
    const listDataOnlyValue =
      onBoardingFormDataOnlyValue && _.cloneDeep(onBoardingFormDataOnlyValue);
    if (values.isGrouping) {
      const findValIndex = listData.steps[activeCategory]['sections'][
        activeGrouping
      ]['attributes'].findIndex((item) => item.name === values.name);
      listData.steps[activeCategory]['sections'][activeGrouping]['attributes'][
        findValIndex
      ]['value'] = event.value;
      listData.steps[activeCategory]['sections'][activeGrouping]['attributes'][
        findValIndex
      ]['error'] = event.error;
      if (
        listDataOnlyValue?.steps &&
        ((event.value !== undefined && event.eventType !== 'blur') ||
          (event.value !== '' && event.eventType === 'blur'))
      ) {
        listDataOnlyValue.steps[activeCategory]['sections'][activeGrouping][
          'attributes'
        ][findValIndex]['value'] = event.value ? event.value : undefined;
      }
    } else if (values.group) {
      const findGroupIndex = listData.steps[activeCategory]['sections'][
        activeGrouping
      ]['groups'].findIndex((item) => item.name === values.groupID);

      const findValIndex = listData.steps[activeCategory]['sections'][
        activeGrouping
      ]['groups'][findGroupIndex]['attributes'].findIndex(
        (item) => item.name === values.name
      );
      const findInput =
        listData.steps[activeCategory]['sections'][activeGrouping]['groups'][
          findGroupIndex
        ]['attributes'][findValIndex];
      const findInputOnlyValue =
        listDataOnlyValue?.steps &&
        listDataOnlyValue.steps[activeCategory]['sections'][activeGrouping][
          'groups'
        ][findGroupIndex]['attributes'][findValIndex];
      findInput['value'] = event.value;
      findInput['error'] = event.error;
      if (
        listDataOnlyValue?.steps &&
        ((event.value !== undefined && event.eventType !== 'blur') ||
          (event.value !== '' && event.eventType === 'blur'))
      ) {
        findInputOnlyValue['value'] = event.value ? event.value : undefined;
      }
      if (event.productDocMetaInfoId) {
        findInput['productDocMetaInfoId'] = event.productDocMetaInfoId;
      }
    } else {
      const findValIndex = listData.steps[activeCategory]['attributes'].findIndex(
        (item) => item.name === values.name
      );

      if (
        values.attributeType === DynamicFieldConstants.TEXT_AND_FILE &&
        Array.isArray(event.value)
      ) {
        listData.steps[activeCategory]['attributes'][findValIndex]['attributes'][0][
          'value'
        ] = event.value;
        listData.steps[activeCategory]['attributes'][findValIndex]['attributes'][0][
          'error'
        ] = event.error;
        if (
          listDataOnlyValue?.steps &&
          ((event.value !== undefined && event.eventType !== 'blur') ||
            (event.value !== '' && event.eventType === 'blur'))
        ) {
          listDataOnlyValue.steps[activeCategory]['attributes'][findValIndex][
            'value'
          ] = event.value ? event.value : undefined;
        }
      } else {
        listData.steps[activeCategory]['attributes'][findValIndex]['value'] =
          event.value;
        listData.steps[activeCategory]['attributes'][findValIndex]['error'] =
          event.error;
        if (
          listDataOnlyValue?.steps &&
          ((event.value !== undefined && event.eventType !== 'blur') ||
            (event.value !== '' && event.eventType === 'blur'))
        ) {
          listDataOnlyValue.steps[activeCategory]['attributes'][findValIndex][
            'value'
          ] = event.value ? event.value : undefined;
        }
      }
    }

    if (event.type === DynamicFieldConstants.FILE) {
      if (
        prodBasicInfo.status.statusId !== draftStatusId &&
        !values?.group &&
        values.key !== FieldConstants.prodBasicInfo
      ) {
        showConfirmModal(event, values, 'addDoc');
        // Here return is not to add the new changes to local state. Otherwise action button will be enabled
        return;
      } else {
        handleUploadFile(event, values);
      }
    }

    if (event.name === FieldConstants.hsnCode) {
      setHsnData({
        value: event.value,
        min: values?.validation?.rule?.length?.min
      });
    }

    dispatch(saveFormJsonData(listData));
    dispatch(saveFormJsonDataOnlyValue(listDataOnlyValue));
  };

  const handleGroupElementId = (groupData, additionalInfo) => {
    if (groupData && groupData?.groups?.length > 0) {
      const listData = _.cloneDeep(onBoardingFormData);
      const listDataOnlyValue =
        onBoardingFormDataOnlyValue && _.cloneDeep(onBoardingFormDataOnlyValue);
      const groupDataOnlyValue = [];
      const modifiedGroupData = groupData?.groups.map(
        (onBoardingGroupData, index) => {
          const groupAttributesOnlyValue = [];
          const result = onBoardingGroupData.xpath
            .split('/')
            .map((element) => element.trim().toString())
            .filter((element) => element !== '');
          const assignField = getNestedVal(additionalInfo, result);
          onBoardingGroupData.attributes.map((item) => {
            if (assignField && assignField[index]?._id) {
              item._id = assignField[index]?._id;
            }
            groupAttributesOnlyValue.push({
              name: item.name,
              xpath: item.xpath,
              value: item.value ? item.value : undefined
            });
            return item;
          });
          groupDataOnlyValue.push({
            name: onBoardingGroupData.name,
            xpath: onBoardingGroupData.xpath,
            attributes: groupAttributesOnlyValue
          });
          return onBoardingGroupData;
        }
      );
      listData.steps[activeCategory]['sections'][activeGrouping]['groups'] =
        modifiedGroupData;
      dispatch(saveFormJsonData(listData));
      if (
        listDataOnlyValue &&
        listDataOnlyValue.steps &&
        groupDataOnlyValue?.length > 0
      ) {
        listDataOnlyValue.steps[activeCategory]['sections'][activeGrouping][
          'groups'
        ] = groupDataOnlyValue;
        dispatch(saveFormJsonDataOnlyValue(listDataOnlyValue));
      }
    }
  };

  const handleStepCategory = (index) => {
    setActiveCategory(index);
    setActiveGrouping(0);
  };

  const handleDeleteFile = (file, item, type) => {
    const data = dispatch(deleteFile(file, categoryId));
    data.then(() => {
      dispatch(
        getProductDocsListById(prodBasicInfo.productId, item.documentTypeId)
      ).then((filterData) => {
        let filteredData = [];
        const xpathValues = item?.xpath?.split('/');
        const valuesToCheck = [
          FieldConstants.productHighlights,
          FieldConstants.caseStudySection,
          FieldConstants.caseStudy
        ];
        const isProductHighlights = valuesToCheck.some((value) =>
          xpathValues?.includes(value)
        );
        filteredData = filterData && filterData?.filter((item) => item.uid);
        const uploadFileData = { value: [] };
        uploadFileData.value = filteredData;
        if (isProductHighlights) {
          uploadFileData.value = [];
        }
        handleChange(uploadFileData, item);
      });
    });

    if (type === 'removeDoc') {
      // Call state change API to move the product to draft
      dispatch(
        updateProductStatus(
          draftStatusId,
          prodBasicInfo.productId,
          categoryId,
          userCompanyStatus?.companyId
        )
      );
    }
  };

  const handleCheckAllFieldFilled = () => {
    const fieldsData = [];
    let isPreventNextPage;

    onBoardingFormData &&
      onBoardingFormData?.steps?.forEach((element) => {
        if (element?.attributes && element?.attributes?.length > 0) {
          fieldsData.push(...element.attributes);
        }
        if (element?.sections && element?.sections?.length > 0) {
          element.sections.forEach((sectionAttributes) => {
            if (
              sectionAttributes?.attributes &&
              sectionAttributes?.attributes?.length > 0
            ) {
              fieldsData.push(...sectionAttributes?.attributes);
            }
            if (sectionAttributes?.groups && sectionAttributes?.groups?.length > 0) {
              sectionAttributes.groups.forEach((groupAttributes) => {
                if (
                  groupAttributes?.attributes &&
                  groupAttributes?.attributes?.length > 0
                ) {
                  fieldsData.push(...groupAttributes.attributes);
                }
              });
            }
          });
        }
      });

    for (const element of fieldsData) {
      const rules = element?.validation;
      const required = rules?.rule?.required;
      if (
        (required?.isRequired &&
          (element?.value?.length === 0 || !element?.value)) ||
        (element?.error?.length && element?.error?.length !== 0)
      ) {
        isPreventNextPage = true;
        break;
      }
    }
    return isPreventNextPage;
  };

  const handleSubmitProduct = (requestObj, fieldData) => {
    const handleFilled = handleCheckAllFieldFilled();
    if (handleFilled) {
      setSnackBarMessage(
        i18next.t(
          'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.PLEASE_FILL_ALL_THE_MANDATORY_FIELDS'
        )
      );
      setSeverity(alertSeverity.ERROR);
      handleSnackBar();
      return;
    }
    requestObj.productDetailedInfo = changeReqBody(requestObj.productDetailedInfo);
    const res = dispatch(
      updateFormAdditionalInfo(requestObj, prodBasicInfo.productId)
    );
    res.then(() => {
      if (onBoardingFormData.isQuotation === true && pricingPlans.length === 0) {
        dispatch(
          createPricingPlan(
            {
              currentPlan: {
                userDetails: {
                  userId: localStorage.getItem('userId'),
                  typeOfUser: idConstants.typeOfUsers.b2b,
                  b2bIdentifier: prodBasicInfo?.companyNo
                }
              },
              isQuotationFlow: onBoardingFormData.isQuotation
            },
            prodBasicInfo.productId,
            categoryId
          )
        ).then((res) => {
          triggerGAEvent(fieldData);
          submitProduct([res.planId]);
        });
      } else {
        triggerGAEvent(fieldData);
        submitProduct();
      }
    });
  };

  const updatePricingPlans = async (draftedPlans) => {
    const statusId = IS_MKOSINT_TENANT
      ? idConstants.published.statusId
      : idConstants.submitted.statusId;
    const pricingResponse = await dispatch(
      updatePricingStatus(draftedPlans, prodBasicInfo.productId, statusId)
    );
    return pricingResponse;
  };

  const submitProduct = async (draftPlanId) => {
    let statusUpdate;
    const findValIndex = onBoardingFormData.steps[0]['attributes'].findIndex(
      (item) => item.name === FieldConstants.productName
    );
    const productName =
      onBoardingFormData.steps[0]['attributes'][findValIndex]['value'];

    if (prodBasicInfo.status.statusId !== idConstants.drafted.statusId) {
      const draftedPlans = getDraftedPricingPlan(pricingPlans);
      if (
        handleCheckValuesChanged() &&
        isShowPricingPlanNotification(prodBasicInfo?.status?.statusId, pricingPlans)
      ) {
        if (draftedPlans?.length > 0) {
          const pricingRes = await updatePricingPlans(draftedPlans);
          if (pricingRes) {
            dispatch(handleShowMessage(true));
            return handleSuccessDialogBox();
          } else {
            return;
          }
        }
      }

      if (draftedPlans?.length > 0) {
        const response = await updatePricingPlans(draftedPlans);
        if (!response) {
          return;
        }
      }

      const updateToDraft = dispatch(
        updateProductStatus(
          idConstants.drafted.statusId,
          prodBasicInfo.productId,
          categoryId,
          userCompanyStatus?.companyId
        )
      );
      updateToDraft.then((draftResponse) => {
        if (draftResponse) {
          statusUpdate = dispatch(
            updateProductStatus(
              idConstants.submitted.statusId,
              prodBasicInfo.productId,
              categoryId,
              userCompanyStatus?.companyId
            )
          );
          statusUpdate.then((statusResponse) => {
            if (statusResponse) {
              handleSuccessDialogBox();
              //GA-312,409
              trackEvent(
                location?.state?.isEdit
                  ? 'edit_product_submitted'
                  : 'create_product_submitted',
                GAEventAction.productSubmitted
              );
            }
          });
        }
      });
    } else {
      if (IS_MKOSINT_TENANT) {
        const product = {};
        const tenantIds = [];
        product.productCategoryId = prodBasicInfo.productCategory.categoryId;
        product.productId = prodBasicInfo.productId;

        const draftedPlans = getDraftedPricingPlan(pricingPlans);
        if (draftedPlans?.length > 0) {
          const response = await updatePricingPlans(draftedPlans);
          if (!response) {
            return;
          }
        }

        statusUpdate = dispatch(
          publishProduct(product, 0, tenantIds, userCompanyStatus?.companyId)
        );
      } else {
        const draftedPlans = draftPlanId
          ? draftPlanId
          : getDraftedPricingPlan(pricingPlans);

        if (draftedPlans?.length > 0) {
          const pricingRes = await updatePricingPlans(draftedPlans);
          if (!pricingRes) {
            return;
          }
        }
        statusUpdate = dispatch(
          updateProductStatus(
            idConstants.submitted.statusId,
            prodBasicInfo.productId,
            categoryId,
            userCompanyStatus?.companyId
          )
        );
      }
      statusUpdate.then((statusResponse) => {
        if (statusResponse) {
          if (IS_MKOSINT_TENANT) {
            const payload = {
              productId: prodBasicInfo.productId,
              tenantIds: [BUYER_TENANT_ID]
            };
            dispatch(publishToTenant(payload));
          }
        }
        if (statusResponse) {
          handleSuccessDialogBox();

          //GA-312,409
          trackEvent(
            location?.state?.isEdit
              ? 'edit_product_submitted'
              : 'create_product_submitted',
            GAEventAction.productSubmitted,
            joinWithUnderscore(productName.toLowerCase())
          );
        }
      });
    }
  };

  const handleIsVisible = () => {
    dispatch(postProductVisibilityData(prodBasicInfo?.productId, !isVisibleData));
  };

  const closeInfoPopup = () => {
    setInfoModal({
      openInfoModal: false,
      modalHeading: '',
      modalDescription: '',
      primaryLabel: '',
      secondaryLabel: '',
      type: '',
      data: {}
    });
  };

  // Show the confirmation modal before uploading or removing a document/file
  const showConfirmModal = (file, item, type) => {
    setInfoModal({
      openInfoModal: true,
      modalHeading: i18next.t('DIALOG.EDIT_DOC.TITLE'),
      modalDescription: i18next.t('DIALOG.EDIT_DOC.DESCRIPTION'),
      primaryLabel: i18next.t('DYNAMIC_FORM.LABEL.CONFIRM'),
      secondaryLabel: i18next.t('DYNAMIC_FORM.LABEL.CANCEL'),
      type,
      data: {
        file,
        item
      }
    });
  };

  const onDeleteFile = (file, item) => {
    if (
      prodBasicInfo.status.statusId !== draftStatusId &&
      !item.group &&
      item.key !== FieldConstants.prodBasicInfo
    ) {
      showConfirmModal(file, item, 'removeDoc');
      return;
    }

    handleDeleteFile(file, item);
  };

  return (
    <BaseLayout logout={signOut}>
      <div className='home'>
        {loader && <Loader />}
        {displaySnackBar && (
          <Snackbar
            severity={severity}
            message={snackBarMessage}
            changeState={handleSnackBar}
          />
        )}
        <FormContainer
          activeCategory={activeCategory}
          activeGrouping={activeGrouping}
          handleStepCategory={handleStepCategory}
          handleChange={handleChange}
          handleNextCategory={handleNextCategory}
          blurValue={blurValue}
          setActiveGrouping={setActiveGrouping}
          handlePreviousCategory={handlePreviousCategory}
          onBoardingFormData={onBoardingFormData}
          handleDeleteFile={onDeleteFile}
          showSuccessBoxModal={showSuccessBoxModal}
          handleSuccessDialogBox={handleSuccessDialogBox}
          apiPlans={pricingPlans}
          handleDeletePlan={handleDeletePlan}
          handleAutoSave={handleAutoSave}
          handleCheckValuesChanged={handleCheckValuesChanged}
          initialFormData={initialFormData}
          isVisibleData={isVisibleData}
          handleIsVisible={handleIsVisible}
          onBoardingFormDataOnlyValue={onBoardingFormDataOnlyValue}
          productDocuments={productDocuments}
        />

        {infoModal.openInfoModal && (
          <InfoModal
            isTitleIconRequired={true}
            modalContent={infoModal.modalDescription}
            heading={infoModal.modalHeading}
            okBtnAction={() => {
              if (infoModal.data?.file && infoModal.data?.item) {
                if (infoModal.type === 'removeDoc')
                  handleDeleteFile(
                    infoModal.data.file,
                    infoModal.data.item,
                    infoModal.type
                  );
                else
                  handleUploadFile(
                    infoModal.data.file,
                    infoModal.data.item,
                    infoModal.type
                  );
              }
            }}
            onClose={closeInfoPopup}
            handleSecondaryBtnClick={closeInfoPopup}
            primaryLabel={infoModal.primaryLabel}
            secondaryLabel={infoModal.secondaryLabel}
          />
        )}
      </div>
    </BaseLayout>
  );
};

export default ProductOnboarding;
