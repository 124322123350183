
const getNoDataChartOptions = (data) => ({
    title: {
        text: 'No data available',
        left: 'center',
        top: 'center',
        textStyle: {
            color: '#000', // $--bosch-black
            fontSize: 12,
            fontFamily: 'boschsans, sans-serif' // $bosch-font-family-general
        },
    },
    legend: {
        show: false,
    },
    series: [
        {
            type: 'pie',
            radius: data ? ['40%', '70%'] : '70%',
            center: ['50%', '50%'],
            data: [{ value: 1, name: 'No data', itemStyle: { color: '#000' } }],
            label: {
                show: false,
            },
            labelLine: {
                show: false,
            },
        },
    ],
});

export const richTextStyles = {
    nameStyle: {
        align: 'left',
        width: 100,
        fontSize: 10,
        fontFamily: 'boschsans, sans-serif', // $bosch-font-family-general
        padding: [2, 0, 0, 0],
    },
    valueStyle: {
        align: 'right',
        fontSize: 10,
        fontFamily: 'boschsans, sans-serif', // $bosch-font-family-general
        width: 100,
    }
};

export const getDonutChartOptions = (isSmallDevice, count) => {
    if (!count?.length) {
        return getNoDataChartOptions(true);
    }
    const statusColors = {
        'Order Created': '#ffdf95', // $--bosch-yellow-90
        'Order Confirmed': '#eec100',    // $--bosch-yellow-80     
        'Order Processing': '#56b0ff', // $--bosch-blue-70
        'Order Completed': '#86d7a2', // $--bosch-green-80
        'Diy Tenant Order Placed': '#37a264', // $--bosch-green-60
        'Order Rejected': '#ff9d9d', // $--bosch-red-75
        'Order Cancelled': '#e8b6e3', // $--bosch-purple-80
        'Order Failed': '#ff5152', // $--bosch-red-60
        'Unsubscribed': '#a4abb3', // $--bosch-gray-70
        'Purchased': '#37a264'// $--bosch-green-60
    };

    const colors = count.map(item => statusColors[item.name] || '#c1c7cc'); //$--bosch-gray-80
    return {
        color: colors,
        tooltip: {
            trigger: 'item',
            formatter: '{b}: {c} ({d}%)',
            textStyle: {
                fontSize: 10,
                fontFamily: 'boschsans, sans-serif' // $bosch-font-family-general
            },
        },
        legend: {
            orient: isSmallDevice ? 'horizontal' : 'vertical',
            left: isSmallDevice ? 'center' : 'auto',
            right: isSmallDevice ? 'auto' : '5%',
            top: isSmallDevice ? '80%' : 'middle',
            textStyle: {
                fontSize: 10,
                fontWeight: 'normal',
            },
            itemWidth: isSmallDevice ? 8 : 11,
            itemHeight: isSmallDevice ? 8 : 11,
            formatter: function (name) {
                const item = count?.find((d) => d.name === name);
                return `${name}: ${item?.value || 0}`;
            },
            data: count ? count.map((item) => item.name) : [],
            selectedMode: true,
        },
        series: [
            {
                type: 'pie',
                radius: ['40%', '70%'],
                center: isSmallDevice ? ['50%', '40%'] : ['20%', '50%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center',
                    formatter: function (params) {
                        const name = params.name.split(' ').join('\n');
                        return `{b|${name}}`;
                    },
                    rich: {
                        b: {
                            fontSize: 12,
                            fontWeight: 'bold',
                            align: 'center',
                            lineHeight: 20,
                        },
                    },
                },
                emphasis: {
                    scale: true,
                    focus: 'self',
                    label: {
                        show: true,
                        fontSize: '12',
                        fontWeight: 'bold',
                        formatter: function (params) {
                            const name = params.name.split(' ').join('\n');
                            return `{b|${name}}`;
                        },
                    },
                },
                labelLine: {
                    show: false,
                },
                data: count || [],
            },
        ],
    };
};

export const getPieChartOptions = (isSmallDevice, count) => {
    if (!count?.length > 0) {
        return getNoDataChartOptions(false);
    }

    const statusColors = {
        Drafted: '#eec100',    // $--bosch-yellow-80     
        Submitted: '#0088d4', // $--bosch-blue-45
        Approved: '#37a264',    // $--bosch-green-60
        'Publication Pending': '#56b0ff', // $--bosch-blue-70
        'Publish In Progress': '#e472db', // $--bosch-purple-65
        Published: '#86d7a2', // $--bosch-green-80
        Rejected: '#ff9d9d', // $--bosch-red-75
    };
    const colors = count.map(item => statusColors[item.name] || '#c1c7cc'); // $--bosch-gray-80

    return {
        color: colors,
        tooltip: {
            trigger: 'item',
            formatter: '{b}: {c} ({d}%)',
            textStyle: {
                fontSize: 10,
                fontFamily: 'boschsans, sans-serif' // $bosch-font-family-general
            },
        },
        legend: {
            orient: isSmallDevice ? 'horizontal' : 'vertical',
            left: isSmallDevice ? 'center' : 'auto',
            right: isSmallDevice ? 'auto' : '5%',
            top: isSmallDevice ? 'bottom' : 'middle',
            textStyle: {
                fontSize: 10,
                fontWeight: 'normal',
            },
            itemWidth: isSmallDevice ? 8 : 11,
            itemHeight: isSmallDevice ? 8 : 11,
            selected: false,
            formatter: function (name) {
                const item = count?.find((d) => d.name === name);
                return `${name}: ${item?.value || 0}`;
            },
            data: count ? count.map((item) => item.name) : [],
        },
        series: [
            {
                type: 'pie',
                radius: '70%',
                avoidLabelOverlap: false,
                center: isSmallDevice ? ['50%', '50%'] : ['20%', '50%'],
                label: {
                    show: false,
                    position: 'center',
                    rich: {
                        b: {
                            fontSize: 12,
                            fontWeight: 'bold',
                            align: 'center',
                        },
                    },
                },
                emphasis: {
                    label: {
                        show: false,
                        fontSize: '12',
                        fontWeight: 'bold',
                    },
                },
                labelLine: {
                    show: false,
                },
                data: count || [],
            },
        ],
    };
};

export const getColumnChartOptions = (count, totalValue) => {
    const noDataAvailable = count.length === 0 || count.every((item) => !item.value);

    return {
        title: {
            text: noDataAvailable ? 'No data available' : '',
            left: 'center',
            top: 'middle',
            textStyle: {
                color: '#000', // $--bosch-black
                fontSize: 12,
                fontFamily: 'boschsans, sans-serif' // $bosch-font-family-general
            }
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            appendToBody: true,
            formatter: function (params) {
                const data = params[0].data;
                const percentage = ((data.value / totalValue) * 100).toFixed(2);
                const formattedValue = data.value === 0 ? data.value : (data.value / 1000) + 'k';
                return `${params[0].name}: ${formattedValue} (${percentage}%)`;
            },
            borderColor: '#56b0ff', // $--bosch-blue-70
            borderWidth: 1,
        },
        grid: {
            left: '3%',
            right: '5%',
            bottom: '5%',
            top: '12%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: noDataAvailable ? [] : count.map((item) => item.name),
            axisLabel: {
                interval: 0,
                formatter: function (name) {
                    return name.split(' ').join('\n');
                },
                margin: 15
            },
            nameGap: 30
        },
        yAxis: {
            type: 'value',
            show: !noDataAvailable,
            axisLabel: {
                formatter: function (value) {
                    return (value / 1000).toFixed(1) + 'k';
                }
            },
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'dashed',
                    color: '#e0e2e5', // $--bosch-gray-90
                    width: 1
                },
                z: 0
            }
        },
        series: [
            {
                type: 'bar',
                data: noDataAvailable ? [] : count.map((item) => item.value),
                animationDuration: 1000,
                animationEasing: 'elasticOut',
                itemStyle: {
                    color: '#56b0ff', // $--bosch-blue-70
                    opacity: 0.7
                },
                label: {
                    show: false
                },
                barCategoryGap: '30%',
                z: 999
            }
        ],
        backgroundColor: noDataAvailable ? '#fff' : undefined, // $--bosch-white
    };
};

export const dateOptions = [
    {
        displayName: 'This month',
        value: 'current',
    },
    {
        displayName: 'Last month',
        value: '0'
    },
    {
        displayName: 'Last 3 months',
        value: '90'
    },
    {
        displayName: 'Last 6 months',
        value: '180'
    },
    {
        displayName: 'All',
        value: 'All'
    }
];

export const orderHeaderOptions = (activeCategoryList, dateOptions, categoryId, orderCategoryChangeHandler, orderDateHandler, dateOption) => [
    {
        id: '1',
        label: '',
        type: 'SINGLE_SELECT',
        options: activeCategoryList,
        value: categoryId,
        onChange: orderCategoryChangeHandler,
    },
    {
        id: '2',
        label: '',
        type: 'SINGLE_SELECT',
        options: dateOptions,
        value: dateOption,
        onChange: orderDateHandler,
    }
];

export const productHeaderOptions = (activeCategoryList, categoryId, productCategoryChangeHandler, dateOptions, productDateHandler, dateOption) => [
    {
        id: '1',
        label: '',
        type: 'SINGLE_SELECT',
        options: activeCategoryList,
        value: categoryId,
        onChange: productCategoryChangeHandler
    },
    {
        id: '2',
        label: '',
        type: 'SINGLE_SELECT',
        options: dateOptions,
        value: dateOption,
        onChange: productDateHandler
    }
];

export const revenueHeaderOptions = (activeCategoryList, dateOptions, categoryId, revenueCategoryChangeHandler, revenueDateHandler, dateOption) => [
    {
        id: '1',
        label: '',
        type: 'SINGLE_SELECT',
        options: activeCategoryList,
        value: categoryId,
        onChange: revenueCategoryChangeHandler,
    },
    {
        id: '2',
        label: '',
        type: 'SINGLE_SELECT',
        options: dateOptions,
        value: dateOption,
        onChange: revenueDateHandler,
    }
];

export const topSellingProductsColumnDefs = (CustomCell, CustomHeader) => [
    {
        field: 'name',
        headerName: 'Name',
        cellRenderer: CustomCell
    },
    {
        field: 'quantity',
        headerName: 'Quantity',
        suppressHeaderFilterButton: true,
        minWidth: 120
    },
    {
        field: 'gmv',
        headerName: 'GMV (INR)',
        headerComponent: CustomHeader,
        sortable: true,
        minWidth: 120
    }
];

export const orderFooterOptions = (orderFooterLinkHandler) => [
    {
        id: '1',
        label: 'View Orders',
        type: 'LINK',
        onClick: orderFooterLinkHandler
    }
];
export const productFooterOptions = (productFooterLinkHandler) => [
    {
        id: '1',
        label: 'View Products',
        type: 'LINK',
        onClick: productFooterLinkHandler
    }
];

export const toMyOrders = '/dashboard/order-management';

export const toMyProducts = '/dashboard/myproducts';

export const defaultPlaceholderFields = {
    config: {
        className: '',
        header: {
            showHeaderLabel: true,
            headerDropdownItems: [
                { id: 1, label: 'Dropdown 1' },
                { id: 2, label: 'Dropdown 2' }
            ]
        },
        body: true,
        footer: {
            footerItems: [
                { id: 1, label: 'Footer Field 1' }
            ]
        }
    }
};

export const revenuePlaceholderFields = {
    config: {
        className: '',
        header: {
            showHeaderLabel: true,
            headerDropdownItems: [
                { id: 1, label: 'Dropdown 1' },
                { id: 2, label: 'Dropdown 2' }
            ]
        },
        body: true
    }
};

export const topSellingProductPlaceholderFields = {
    config: {
        className: '',
        header: {
            showHeaderLabel: true,
        },
        body: true
    }
};


