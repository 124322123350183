import React, { useState, createRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import getUserId from '../../utils/get-user-id';

import {
  NavigationButtons,
  VerticleStepper,
  Dropdown,
  TextInput,
  NumberInput,
  MultiSelect,
  TextArea,
  UserManual,
  ImageUpload,
  ThumbUpload,
  CoverUpload,
  VideoUpload,
  AddPricingPlanCard,
  CheckBox,
  Breadcrumb,
  GroupingCard,
  Attachment,
  DialogBox,
  RichTextEditorInput,
  StepIndicator,
  CategoryCard,
  DatePicker,
  IconButton
} from '../../msreact-kit/packages/html-components';
import PricingCard from '../../components/pricing/pricing-card';
import { DynamicFieldConstants } from '../../shared/constants';
import {
  deleteFile,
  deleteProductGroup,
  getProductDocsListById,
  handleSaveTermsAndCondition,
  saveFormJsonData,
  handleSaveDPNDocument,
  saveFormJsonDataOnlyValue
} from '../../actions/api/hardwareAction';
import loading from '../../actions/LoaderAction';
import FormEditWrapper from '../../msreact-kit/html-components/form-edit-wrapper';
import PreviewModal from '../../msreact-kit/html-components/preview-modal';
import {
  canPricingPlanBeEdited,
  downloadFile,
  handleCheckUploadType,
  handleDownload,
  handlePricingPlanDeletion,
  isShowPricingPlanNotification,
  isValidJSONString
} from '../../shared/shared-function/services';
import { handleParseXpath } from '../../shared/payload-parser/basic-info-parser';
import PricingPlanDialog from '../../components/pricing/pricing-plan-dialog';
import {
  FieldConstants,
  minPlanBenefitsToShow,
  TimeoutConstant
} from '../../shared/constants/field-constants';
import idConstants from '../../data/idConstants';
import { getUploadedDoc } from '../../shared/shared-function/helperFunctions';
import PricingPlanScreen from '../../components/pricing/PricingPlanScreen';
import ContactSalesPricingCard from '../../components/pricing/PricingPlanScreen/contact-sales';
import i18next from 'i18next';
import Quotations from '../quotation-form-container';
import { getNestedVal, dynamicCompare } from '../../shared/api-parser/clone-parser';
import VisibilitySettings from '../../components/Shared/VisibilitySetting';
import {
  TENANT,
  IS_MKOSINT_TENANT,
  SELLER_TENANT_ID
} from '../../actions/configuration';
import { getRegionCode } from '../../utils/get-region-details';
import { regionDetails } from '../../data/appConstants';
import PricingNotification from '../../components/pricing/pricing-notification';
import PricingStatusNotification from '../../components/pricing/pricing-status-notification';
import InfoModal from '../../components/Shared/Utils/Modal/InformationModal';
import { updatePricingPlanStatus } from '../../actions/api/pricing-plan-action';
import images from '../../assets/img';
import { getAutoSaveInfo } from '../../utils/date-time-utils';
import dialogText from '../../data/dialogText';

const FormContainer = (props) => {
  const {
    onBoardingFormData,
    onBoardingFormDataOnlyValue,
    activeCategory,
    handleStepCategory,
    handleChange,
    blurValue,
    apiPlans,
    handleNextCategory,
    activeGrouping,
    setActiveGrouping,
    handlePreviousCategory,
    isFormEdit,
    handleDeleteFile,
    showSuccessBoxModal,
    handleSuccessDialogBox,
    handleDeletePlan,
    handleAutoSave,
    handleCheckValuesChanged,
    initialFormData,
    isVisibleData,
    handleIsVisible,
    productDocuments
  } = props;

  const {
    prodBasicInfoData,
    termsAndConditionFile,
    prodDetailedInfo,
    dpnDocument,
    isShowSuccessMsg
  } = useSelector((state) => {
    return {
      prodBasicInfoData: state.FormReducer.prodBasicInfo,
      termsAndConditionFile: state.FormReducer.termsAndConditionFile,
      prodDetailedInfo: state.FormReducer.prodDetailedInfo,
      dpnDocument: state.FormReducer.dpnDocument,
      isShowSuccessMsg: state.PricingPlanReducer.isShowSuccessMsg
    };
  });

  const dispatch = useDispatch();
  const [planData, setPlanData] = useState([]);
  const [pricingPlanModal, setShowPricingPlanModal] = useState(false);
  const [showDialogBoxModal, setShowDialogBoxModal] = useState(false);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [planPreviewData, setPlanPreviewData] = useState({});
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [resetUploadError, setResetUploadError] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    isShow: false,
    title: '',
    subTitle: ''
  });
  const [bestAvailabilityFlag, setBestAvailabilityFlag] = useState(false);
  let currentTermsObject,
    currentAdapterAccessObject,
    currentAdapterLocationObject,
    currentAvailabilityObject;
  const [checkboxVisible, setCheckboxVisible] = useState(false);
  const [infoModal, setInfoModal] = useState({
    openInfoModal: false,
    modalHeading: '',
    modalDescription: ''
  });
  const [editPlan, setEditPlan] = useState(null);
  const history = useHistory();
  const idleTimer = createRef();

  const locationState = history.location.state;

  const dynamicData =
    onBoardingFormData?.steps?.length > 0 &&
    onBoardingFormData.steps[activeCategory];

  const internalSellerPortal =
    process.env.REACT_APP_SELLER_TENANT_ID === TENANT.MKOSINT;

  const isShowSubmitNotification =
    !onBoardingFormData?.isQuotation &&
    prodBasicInfoData?.status?.statusId !== idConstants.submitted.statusId &&
    isShowPricingPlanNotification(
      prodBasicInfoData?.status?.statusId,
      apiPlans,
      idConstants.submitted.statusId
    );

  const isShowDraftNotification =
    !onBoardingFormData?.isQuotation &&
    isShowPricingPlanNotification(prodBasicInfoData?.status?.statusId, apiPlans);

  const isShowNotification =
    prodBasicInfoData?.status &&
    !IS_MKOSINT_TENANT &&
    !showSuccessBoxModal &&
    (isShowSubmitNotification || isShowDraftNotification);

  const getInitialSuccessBoxModalValue = () => {
    if (SELLER_TENANT_ID === TENANT.MKOSINT) {
      return {
        title: isShowSuccessMsg
          ? i18next.t(
              'DYNAMIC_FORM.SHARED.SUCCESS_MESSAGE.PRICING_DETAILS_SUBMITTED_SUCCESSFULLY'
            )
          : i18next.t('DYNAMIC_FORM.PRODUCT_CONTAINER.PUBLISHED_SUCCESSFULLY'),
        subTitle: ''
      };
    }
    return {
      title: isShowSuccessMsg
        ? ''
        : i18next.t(
            'DYNAMIC_FORM.SHARED.SUCCESS_MESSAGE.DETAILS_SUBMITTED_SUCCESSFULLY'
          ),
      subTitle: isShowSuccessMsg
        ? i18next.t(
            'DYNAMIC_FORM.SHARED.SUCCESS_MESSAGE.PRICING_DETAILS_SUBMITTED_SUCCESSFULLY'
          )
        : i18next.t(
            'DYNAMIC_FORM.SHARED.SUCCESS_MESSAGE.PRODUCT_DETAILS_ARE_SUBMITTED_SUCCESSFULLY_FOR_APPROVAL'
          )
    };
  };

  const initialSuccessBoxModalValue = getInitialSuccessBoxModalValue();
  const handleIdle = (event) => {
    const ifClickEvent = event?.target;
    if (
      dynamicData &&
      (!prodBasicInfoData?.status ||
        prodBasicInfoData.status.statusId === idConstants.drafted.statusId)
    ) {
      handleAutoSave(
        dynamicData?.sections?.length > 0
          ? dynamicData['sections'][activeGrouping]
          : dynamicData,
        dynamicData,
        handleDisbleNextBtn,
        ifClickEvent
      );
    }
  };

  const handleCloseDialogBox = () => {
    setShowDialogBoxModal((prevState) => !prevState);
  };

  // Commenting below code as preview of product is currently unavailable.
  // const handleProductPreview = () => {
  //   setProductPreviewModal((prevState) => !prevState);
  // };

  const handlePreviewModal = (file = null) => {
    setShowPreviewModal((prevState) => !prevState);
    setPreviewData(file);
  };

  const handlePlanModal = (file = null) => {
    setShowPlanModal((prevState) => !prevState);
    setPlanPreviewData(file);
  };

  const handleViewDocument = (data) => {
    if (data.blobFileUrl) {
      handleDownloadFile(data.blobFileUrl, data.documentName);
    } else {
      handleDownload(data);
    }
  };

  const handleDownloadFile = (url, fileName) => {
    dispatch(loading(true));
    downloadFile(url, fileName).then(() => {
      dispatch(loading(false));
    });
  };

  const handleCheckboxChange = () => {
    setCheckboxChecked((prev) => !prev);
  };

  const handleUpdateTermsAndCondition = (file) => {
    dispatch(handleSaveTermsAndCondition(file));
  };

  const handleFileSelect = (data) => {
    let file = data.value[0];
    file['documentName'] = file?.name;
    handleUpdateTermsAndCondition(data.value);
  };

  const handleUpdateDPNDocument = (file) => {
    dispatch(handleSaveDPNDocument(file));
  };

  const handleDPNFileSelect = (data, item) => {
    let file = data.value[0];
    file['documentName'] = file?.name;
    data.value[0].documentTypeId = item.documentTypeId;
    handleUpdateDPNDocument(data.value);
  };

  const handleRemoveFile = () => {
    handleUpdateTermsAndCondition(null);
    if (prodBasicInfoData.productId) {
      dispatch(
        getProductDocsListById(
          prodBasicInfoData.productId,
          idConstants.documentType.termsAndConditionsDocument
        )
      ).then((docRes) => {
        if (docRes?.length > 0) {
          dispatch(
            deleteFile(docRes[0], prodBasicInfoData.productCategory.categoryId)
          );
        }
      });
    }
  };

  const handleTextChange = (event, data) => {
    handleChange(event, data);
    setResetUploadError(true);
  };

  const handleBestAvailabilityInputChange = (event, data) => {
    handleChange(event, data);
    event.value?.value === 'Workflow'
      ? setBestAvailabilityFlag(true)
      : setBestAvailabilityFlag(false);
  };

  useEffect(() => {
    handleUpdateTermsAndCondition(null);
    handleUpdateDPNDocument(null);
  }, []);

  const handleBestFieldValidation = (currentItemName, currentItemObject) => {
    const findItemIndex =
      onBoardingFormData?.steps?.length > 0 &&
      onBoardingFormData?.steps[activeCategory]['attributes'].findIndex(
        (item) => item.name === currentItemName
      );
    currentItemObject = {
      ...currentItemObject,
      error: '',
      validation: {
        ...currentItemObject?.validation,
        rule: {
          ...currentItemObject?.validation?.rule,
          required: {
            ...currentItemObject?.validation?.rule?.required,
            isRequired: bestAvailabilityFlag ? true : false
          }
        }
      }
    };
    if (onBoardingFormData.steps) {
      onBoardingFormData.steps[activeCategory].attributes[findItemIndex] =
        currentItemObject;
      dispatch(saveFormJsonData(onBoardingFormData));
    }
  };

  const handleTextAndFileFieldValidation = (itemName, currentObject) => {
    const findValIndex =
      onBoardingFormData?.steps?.length > 0 &&
      onBoardingFormData?.steps[activeCategory]['attributes'].findIndex(
        (item) => item.name === itemName
      );
    const initialObject = _.cloneDeep(currentObject);
    if (initialObject?.validation?.rule?.required?.errorMessage) {
      initialObject.validation.rule.required.isRequired =
        !initialObject.validation.rule.required.isRequired;
      initialObject.error = '';
    }

    if (onBoardingFormData.steps) {
      onBoardingFormData.steps[activeCategory].attributes[findValIndex] =
        initialObject;
      dispatch(saveFormJsonData(onBoardingFormData));
    }
  };

  const updateConditionalFields = (stepper) => {
    if (stepper?.attributes) {
      stepper.attributes?.forEach((item) => {
        if (
          item.attributeType === DynamicFieldConstants.TEXT_AND_FILE &&
          item.attributes[0].value
        ) {
          item.validation.rule.required.isRequired = false;
        }
      });
    }
  };

  useEffect(() => {
    if (!internalSellerPortal) return;
    handleBestFieldValidation('adapterAccess', currentAdapterAccessObject);
    handleBestFieldValidation('adapterLocation', currentAdapterLocationObject);
  }, [bestAvailabilityFlag]);

  useEffect(() => {
    if (IS_MKOSINT_TENANT && productDocuments?.length > 1) {
      onBoardingFormData?.steps?.forEach((stepper) => {
        updateConditionalFields(stepper);
      });
    }
  }, [productDocuments]);

  useEffect(() => {
    if (
      onBoardingFormData &&
      currentAvailabilityObject?.value?.value === 'Workflow'
    ) {
      setBestAvailabilityFlag(true);
    }
  }, [activeCategory]);

  useEffect(() => {
    if (termsAndConditionFile) {
      const findValIndex =
        onBoardingFormData?.steps?.length > 0 &&
        onBoardingFormData?.steps[activeCategory]['attributes'].findIndex(
          (item) => item.name === 'termsAndConditions'
        );

      currentTermsObject = {
        ...currentTermsObject,
        error: '',
        validation: {
          ...currentTermsObject?.validation,
          rule: {
            ...currentTermsObject?.validation?.rule,
            required: {
              ...currentTermsObject?.validation?.rule?.required,
              isRequired: false
            }
          }
        }
      };

      if (
        currentTermsObject.attributeType !== DynamicFieldConstants.RICHTEXTEDITOR
      ) {
        currentTermsObject.value = '';
      }

      if (onBoardingFormData.steps) {
        onBoardingFormData.steps[activeCategory].attributes[findValIndex] =
          currentTermsObject;
        dispatch(saveFormJsonData(onBoardingFormData));
      }
    } else {
      const findValIndex =
        onBoardingFormData?.steps?.length > 0 &&
        onBoardingFormData?.steps[activeCategory]['attributes'].findIndex(
          (item) => item.name === 'termsAndConditions'
        );
      if (currentTermsObject && Object.keys(currentTermsObject).length) {
        currentTermsObject = {
          ...currentTermsObject,
          error:
            isValidJSONString(currentTermsObject?.value) &&
            JSON.parse(currentTermsObject?.value).blocks[0].text &&
            !termsAndConditionFile
              ? ''
              : `${i18next.t(
                  'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.TERMS_AND_CONDITIONS_IS_REQUIRED'
                )}.`,
          validation: {
            ...currentTermsObject?.validation,
            rule: {
              ...currentTermsObject?.validation?.rule,
              required: {
                ...currentTermsObject?.validation?.rule?.required,
                isRequired:
                  isValidJSONString(currentTermsObject?.value) &&
                  JSON.parse(currentTermsObject?.value).blocks[0].text &&
                  !termsAndConditionFile
                    ? false
                    : true
              }
            }
          }
        };
        if (onBoardingFormData.steps) {
          onBoardingFormData.steps[activeCategory].attributes[findValIndex] =
            currentTermsObject;
          dispatch(saveFormJsonData(onBoardingFormData));
        }
      }
    }
  }, [termsAndConditionFile]);

  useEffect(() => {
    if (Object.keys(initialFormData).length && !termsAndConditionFile) {
      if (locationState?.isEdit) {
        const uploadedDocument = getUploadedDoc(initialFormData, false);
        uploadedDocument
          ? handleUpdateTermsAndCondition([uploadedDocument])
          : handleUpdateTermsAndCondition(null);

        // Show Popup for an published product
        if (prodBasicInfoData?.status?.statusId === idConstants.published.statusId) {
          setInfoModal({
            openInfoModal: true,
            modalHeading: i18next.t('DIALOG.EDIT_INFO.TITLE'),
            modalDescription: i18next.t('DIALOG.EDIT_INFO.DESCRIPTION'),
            isTitleIconRequired: true,
            secondaryLabel: i18next.t('FORM.CANCEL')
          });
        }
      }
    }
  }, [initialFormData]);

  const handleConfirmDelete = (data) => {
    handleDeletePlan(data);
    handleConfirmationModal({ isShow: false });
  };

  const handlePricingInfoModal = (description) => {
    setInfoModal({
      openInfoModal: true,
      modalHeading: i18next.t('MY_ACCOUNT.INFORMATION'),
      modalDescription: description,
      takeNoAction: true,
      primaryLabel: i18next.t('DYNAMIC_FORM.SHARED.BUTTON.OK'),
      secondaryLabel: null
    });
  };

  const editPricingPlan = (plan, pricingJson) => {
    if (canPricingPlanBeEdited(plan)) {
      setEditPlan(plan);
      setPlanData(pricingJson);
      setShowPricingPlanModal((prevState) => !prevState);
    } else {
      setInfoModal({
        openInfoModal: true,
        modalHeading: i18next.t('MY_ACCOUNT.INFORMATION'),
        modalDescription: i18next.t('SHARED.CAN_NOT_EDIT_INFORMATION'),
        takeNoAction: true,
        primaryLabel: i18next.t('DYNAMIC_FORM.SHARED.BUTTON.OK'),
        secondaryLabel: null
      });
    }
  };

  const handleConfirmDeletePlan = (data) => {
    const activePlans = apiPlans?.filter((filteredPlan) => filteredPlan.enabled);
    const pricingPlanDeletion = handlePricingPlanDeletion(
      data,
      prodBasicInfoData?.status?.statusId,
      activePlans
    );

    if (pricingPlanDeletion) {
      return handlePricingInfoModal(i18next.t(pricingPlanDeletion));
    }

    handleConfirmModal({
      isShow: true,
      title:
        `${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.ARE_YOU_SURE_WANT_TO_DELETE')} "` +
        data?.planName +
        `" ${i18next.t('DYNAMIC_FORM.PRICING_PLAN.PRICING_PLAN').toLowerCase()}?`,
      subTitle: i18next.t(
        'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.ACTION_WILL_PERMANENTLY_REMOVE_THE_PLAN'
      ),
      action: () => handleConfirmDelete(data)
    });
  };

  const closeInfoPopup = () => {
    setInfoModal({
      openInfoModal: false,
      modalHeading: '',
      modalDescription: ''
    });
  };

  const handleConfirmModal = (details) => {
    setConfirmationModal(details);
  };

  const deactivateSubscribedPlan = (plan) => {
    handleConfirmModal({
      isShow: true,
      title:
        `${i18next.t('SHARED.SUBSCRIBED_PLAN_DEACTIVATE_TITLE')} "` +
        plan?.planName +
        `" ${i18next.t('DYNAMIC_FORM.PRICING_PLAN.PRICING_PLAN').toLowerCase()}?`,
      subTitle: i18next.t('SHARED.SUBSCRIBED_PLAN_DEACTIVATE_BODY'),
      action: () => handleConfirmDisablePlan(plan)
    });
  };

  const handleConfirmDisablePlan = (plan) => {
    dispatchUpdatePricingPlanStatus(
      plan,
      prodBasicInfoData?.productId,
      'SHARED.PLAN_IS_DEACTIVATED'
    );
    handleConfirmModal({ isShow: false });
  };

  const showPopUpWithNoAction = (message) => {
    setInfoModal({
      openInfoModal: true,
      modalHeading: i18next.t('MY_ACCOUNT.INFORMATION'),
      modalDescription: message,
      primaryLabel: i18next.t('DYNAMIC_FORM.SHARED.BUTTON.OK'),
      takeNoAction: true
    });
  };

  const dispatchUpdatePricingPlanStatus = (plan, productId, message) => {
    const userDetails = {
      userId: getUserId(),
      typeOfUser: idConstants.typeOfUsers.b2b,
      b2bIdentifier: prodBasicInfoData?.companyNo
    };
    plan.userDetails = plan.userDetails !== null ? plan.userDetails : userDetails;
    dispatch(updatePricingPlanStatus(plan, productId))
      .then((response) => {
        showPopUpWithNoAction(i18next.t(message));
      })
      .catch((error) => {
        error && showPopUpWithNoAction(error);
      });
  };

  const changePlanStatus = (plan) => {
    if (plan?.enabled) {
      handleConfirmModal({
        isShow: true,
        title:
          `${i18next.t('SHARED.SUBSCRIBED_PLAN_DEACTIVATE_TITLE')} "` +
          plan?.planName +
          `" ${i18next.t('DYNAMIC_FORM.PRICING_PLAN.PRICING_PLAN').toLowerCase()}?`,
        subTitle: i18next.t('SHARED.SUBSCRIBED_PLAN_DEACTIVATE_BODY'),
        action: () => {
          if (plan?.subscribed) {
            deactivateSubscribedPlan(plan);
            handleConfirmModal({ isShow: false });
          } else {
            dispatchUpdatePricingPlanStatus(
              plan,
              prodBasicInfoData?.productId,
              'SHARED.PLAN_IS_DEACTIVATED'
            );
            handleConfirmModal({ isShow: false });
          }
        }
      });
    } else {
      dispatchUpdatePricingPlanStatus(
        plan,
        prodBasicInfoData?.productId,
        'SHARED.PLAN_IS_ACTIVATED'
      );
    }
  };

  const renderPricingPlanCard = (data, item, pricingJson) => {
    if (data?.planDetails?.planType === idConstants.pricingPlanType.customPlans) {
      return (
        <ContactSalesPricingCard
          status={data?.status?.description}
          handleDelete={() => handleConfirmDeletePlan(data)}
          productStatusId={prodBasicInfoData?.status?.statusId}
          changePlanStatus={changePlanStatus}
          pricingPlanCardData={data}
        />
      );
    }

    return (
      <PricingCard
        id={data?.planId}
        name={data.planName}
        price={data.planCost}
        isoCode={data.currency.currencyCode}
        benefits={data.planBenefits.slice(0, minPlanBenefitsToShow)}
        chargeableUnit={data.rateLimit.chargeableUnit.chargeableUnitType}
        handleDelete={() => handleConfirmDeletePlan(data)}
        handleChange={() => handlePlanModal(data)}
        data={item}
        billingType={data?.billingType?.billingTypeCode}
        pricingPlanCardData={data}
        productStatusId={prodBasicInfoData?.status?.statusId}
        changePlanStatus={changePlanStatus}
        editPricingPlan={(data) => editPricingPlan(data, pricingJson)}
        rateLimitTypes={onBoardingFormData?.rateLimitTypes}
      />
    );
  };

  const handleGetFieldInput = (item, attributes) => {
    let displayFieldOnScreen = true;
    const termsAndConditionsAttachmentAttribute = attributes?.find(
      (item) => item.name === 'termsAndConditionsAttachment'
    );
    if (
      internalSellerPortal &&
      !bestAvailabilityFlag &&
      (item.name === 'adapterAccess' || item.name === 'adapterLocation')
    )
      displayFieldOnScreen = false;
    if (item.name === 'termsAndConditions' && !item?.isPrevent) {
      currentTermsObject = item;
      let hasTAndCText = false;
      if (
        item?.value &&
        isValidJSONString(item?.value) &&
        JSON.parse(item?.value).blocks[0].text
      ) {
        hasTAndCText = true;
      }

      if (
        item?.value &&
        item?.value?.length > 0 &&
        item.attributeType !== DynamicFieldConstants.RICHTEXTEDITOR
      ) {
        hasTAndCText = true;
      }

      return (
        <div className='terms-and-conditions' key={item.name}>
          <div>
            <h4 className='pdu__displayName -size-l mb6'>
              {i18next.t('SHARED.TERMS_AND_CONDITIONS_CONSENT_HEADING')}
              <span className='input__required'> *</span>
            </h4>
            <div className='-size-m color-light-grey mb12'>
              {i18next.t('SHARED.TERMS_AND_CONDITIONS_SUB_HEADING')}
            </div>
            <FormEditWrapper isFormEdit={isFormEdit} keyVal={item.name}>
              <div>
                {handleGetFieldInput({
                  ...currentTermsObject,
                  handleChange: (event) =>
                    handleTextChange(event, currentTermsObject),
                  error: termsAndConditionFile ? '' : currentTermsObject.error,
                  disabled: termsAndConditionFile,
                  validation: { rule: { required: { isRequired: true } } },
                  isPrevent: true
                },
                attributes)}
              </div>
              {/* upload file as t&c document future release */}
              <UserManual
                handleChange={item.key === 'prodBasicInfo' && !prodBasicInfoData?.productId
                  ? (data) => handleFileSelect(data)
                  : (event) => {
                    // Uploading file directly if productId present
                    termsAndConditionsAttachmentAttribute.key = 'prodBasicInfo';
                    handleFileSelect(event);
                    handleChange(event, termsAndConditionsAttachmentAttribute);
                  }}
                handleDeleteFile={handleRemoveFile}
                prodId={prodBasicInfoData?.productId}
                handlePreviewElement={handleViewDocument}
                value={termsAndConditionFile}
                validation={{
                  supportedFileExtensions: ['.pdf'],
                  maxFiles: 1,
                  maxSize: 5242880,
                  accept: 'image/png,image/jpg,image/jpeg'
                }}
                disabled={termsAndConditionFile || hasTAndCText ? true : false}
                resetError={resetUploadError}
              />
            </FormEditWrapper>
          </div>
        </div>
      );
    } else {
      switch (item.name) {
        case 'adapterLocation':
          currentAdapterLocationObject = item;
          break;
        case 'adapterAccess':
          currentAdapterAccessObject = item;
          break;
        case 'availability':
          currentAvailabilityObject = item;
          break;
      }
      const validateHSNCodeField =
        getRegionCode() !== regionDetails[0].regionCode &&
        item.name === FieldConstants.hsnCode;

      if (
        getRegionCode() === regionDetails[0].regionCode &&
        item.name === FieldConstants.hsnCode &&
        item.attributeType !== 'HIDDEN' &&
        !IS_MKOSINT_TENANT
      ) {
        item.validation.rule.required = {
          isRequired: true
        };
      }
      switch (item.attributeType) {
        case DynamicFieldConstants.TEXT:
          return (
            displayFieldOnScreen && (
              <div
                className={`${
                  item.name === 'productName' &&
                  idConstants.talent.categoryId !== locationState?.productCategoryId
                    ? 'home-wrapper__form__document'
                    : 'home-wrapper__form__input'
                } 
              ${
                (locationState?.isEdit && item.isNonEditableAttributes) ||
                (prodBasicInfoData?.productId && item.name === 'productName')
                  ? 'home-wrapper__form__input-disable'
                  : ''
              }`}
                key={item.name}
              >
                <FormEditWrapper isFormEdit={isFormEdit} keyVal={item.name}>
                  <TextInput
                    handleBlur={blurValue}
                    handleChange={(event) => handleChange(event, item)}
                    disabled={
                      (item?.key !== 'prodBasicInfo' &&
                        !prodBasicInfoData?.productId) ||
                      (locationState?.isEdit && item.isNonEditableAttributes) ||
                      (prodBasicInfoData?.productId && item.name === 'productName')
                        ? true
                        : false
                    }
                    {...item}
                  />
                </FormEditWrapper>
              </div>
            )
          );

        case DynamicFieldConstants.NUMBER:
          return (
            !validateHSNCodeField && (
              <div className='home-wrapper__form__input' key={item.name}>
                <FormEditWrapper isFormEdit={isFormEdit} keyVal={item.name}>
                  <NumberInput
                    handleBlur={blurValue}
                    handleChange={(event) => handleChange(event, item)}
                    disabled={
                      item?.key !== 'prodBasicInfo' && !prodBasicInfoData?.productId
                        ? true
                        : false
                    }
                    {...item}
                  />
                </FormEditWrapper>
              </div>
            )
          );

        case DynamicFieldConstants.TEXT_AREA:
          return (
            <div
              className={`home-wrapper__form__input ${
                item.name === FieldConstants.productShortDesc &&
                `home-wrapper__form__short-description`
              }`}
              key={item.name}
            >
              <FormEditWrapper isFormEdit={isFormEdit} keyVal={item.name}>
                <TextArea
                  handleChange={(event) => handleChange(event, item)}
                  disabled={
                    item?.key !== 'prodBasicInfo' && !prodBasicInfoData?.productId
                      ? true
                      : false
                  }
                  {...item}
                />
              </FormEditWrapper>
            </div>
          );
        case DynamicFieldConstants.RICHTEXTEDITOR:
          return (
            <div className='home-wrapper__form__input' key={item.name}>
              <FormEditWrapper isFormEdit={isFormEdit} keyVal={item.name}>
                <RichTextEditorInput
                  handleChange={(event) => handleChange(event, item)}
                  disabled={
                    item?.key !== 'prodBasicInfo' && !prodBasicInfoData?.productId
                      ? true
                      : false
                  }
                  {...item}
                />
              </FormEditWrapper>
            </div>
          );

        case DynamicFieldConstants.SINGLE_SELECT:
          return (
            displayFieldOnScreen && (
              <div className='home-wrapper__form__input' key={item.name}>
                <FormEditWrapper isFormEdit={isFormEdit} keyVal={item.name}>
                  <Dropdown
                    {...item}
                    handleChange={(event) => {
                      item.name === 'availability' && internalSellerPortal
                        ? handleBestAvailabilityInputChange(event, item)
                        : handleChange(event, item);
                    }}
                    options={item?.options?.sort(dynamicCompare)}
                    disabled={
                      item?.key !== 'prodBasicInfo' && !prodBasicInfoData?.productId
                        ? true
                        : false
                    }
                  />
                </FormEditWrapper>
              </div>
            )
          );
        case DynamicFieldConstants.MULTI_SELECT:
          return (
            <div className={`home-wrapper__form__input`} key={item.name}>
              <FormEditWrapper isFormEdit={isFormEdit} keyVal={item.name}>
                <MultiSelect
                  {...item}
                  values={item.value}
                  handleChange={(event) => {
                    handleRemoveOption(event, item);
                  }}
                  disabled={
                    item?.key !== 'prodBasicInfo' && !prodBasicInfoData?.productId
                      ? true
                      : false
                  }
                  options={item?.options?.sort((option1, option2) =>
                    option1.value.localeCompare(option2.value)
                  )}
                />
              </FormEditWrapper>
            </div>
          );
        case DynamicFieldConstants.THUMB_IMAGE:
          return (
            <div className='home-wrapper__form__document' key={item.name}>
              <FormEditWrapper isFormEdit={isFormEdit} keyVal={item.name}>
                <ThumbUpload
                  handleChange={(event) => handleChange(event, item)}
                  handleDeleteFile={(file) => handleDeleteFile(file, item)}
                  {...item}
                />
              </FormEditWrapper>
            </div>
          );
        case DynamicFieldConstants.COVER_IMAGE:
          return (
            <div className='home-wrapper__form__document' key={item.name}>
              <FormEditWrapper isFormEdit={isFormEdit} keyVal={item.name}>
                <CoverUpload
                  handleChange={(event) => handleChange(event, item)}
                  handleDeleteFile={(file) => handleDeleteFile(file, item)}
                  {...item}
                />
              </FormEditWrapper>
            </div>
          );
        case DynamicFieldConstants.UPLOAD_IMAGE:
          return (
            <div className='home-wrapper__form__document' key={item.name}>
              <FormEditWrapper isFormEdit={isFormEdit} keyVal={item.name}>
                {item.name === idConstants.coverImage ? (
                  <>
                    <CoverUpload
                      handleChange={(event) => handleChange(event, item)}
                      handleDeleteFile={(file) => handleDeleteFile(file, item)}
                      {...item}
                    />
                  </>
                ) : (
                  <>
                    <ImageUpload
                      handleChange={(event) => handleChange(event, item)}
                      handleDeleteFile={(file) => handleDeleteFile(file, item)}
                      handlePreviewElement={handlePreviewModal}
                      disabled={prodBasicInfoData?.productId ? false : true}
                      isHideIcon={true}
                      {...item}
                    />
                  </>
                )}
              </FormEditWrapper>
            </div>
          );
        case DynamicFieldConstants.UPLOAD_VIDEO:
          return (
            <div className='home-wrapper__form__document' key={item.name}>
              <FormEditWrapper isFormEdit={isFormEdit} keyVal={item.name}>
                <VideoUpload
                  handleChange={(event) => handleChange(event, item)}
                  handleDeleteFile={(file) => handleDeleteFile(file, item)}
                  disabled={prodBasicInfoData?.productId ? false : true}
                  {...item}
                />
              </FormEditWrapper>
            </div>
          );
        case 'PICKER':
          return (
            <div className='home-wrapper__form__document' key={item.name}>
              {onBoardingFormData?.isQuotation === true ? (
                <Quotations
                  title={i18next.t('DYNAMIC_FORM.PRICING_PLAN.PRICING')}
                  description={i18next.t(
                    'QUOTATION.BUYER_WILL_REQUEST_FOR_QUOTATION'
                  )}
                  onBoardingFormData={onBoardingFormData}
                />
              ) : (
                <>
                  <h4 className='-size-l'>
                    {i18next.t('DYNAMIC_FORM.PRICING_PLAN.PRICING_PLAN')}
                  </h4>
                  <div className='home-wrapper__form__create-plan-info'>
                    <p>
                      {i18next.t(
                        'DYNAMIC_FORM.PRICING_PLAN.CREATE_PRICING_PLAN_INFO'
                      )}
                    </p>
                    <p>
                      {i18next.t(
                        'DYNAMIC_FORM.PRICING_PLAN.CREATE_PRICING_PLAN_NOTE'
                      )}
                    </p>
                  </div>
                  <div className='home-wrapper__form__plan_card'>
                    {item?.planCardData?.map((data) => {
                      return renderPricingPlanCard(data, item?.planCardData, item);
                    })}
                    {item?.planCardData?.length <
                      onBoardingFormData.pricingPlanMaxCount && (
                      <AddPricingPlanCard
                        onClickCard={() => handlePricingPlanModal(item)}
                        {...item}
                      />
                    )}
                  </div>
                  {item.description && (
                    <p className='home-wrapper__form__plan_card__description'>
                      {i18next.t('DYNAMIC_FORM.PRICING_PLAN.NOTE')}:{' '}
                      {item.description}
                    </p>
                  )}
                  {isShowPricingPlanNotification(
                    prodBasicInfoData?.status?.statusId,
                    apiPlans
                  ) && (
                    <PricingNotification image={images.documentView}>
                      <p className='home-wrapper__form__notification-info'>
                        {i18next.t(
                          'DYNAMIC_FORM.PRICING_PLAN.SUBMIT_DRAFT_PRICING_PLAN_FOR_APPROVAL'
                        )}
                      </p>
                    </PricingNotification>
                  )}
                </>
              )}
            </div>
          );
        case 'checkbox':
          return (
            <div className='home-wrapper__form__document' key={item.name}>
              <FormEditWrapper isFormEdit={isFormEdit} keyVal={item.name}>
                <CheckBox label={item.displayName} {...item} />
              </FormEditWrapper>
            </div>
          );
        case DynamicFieldConstants.ATTACHMENT:
          return (
            <div className='home-wrapper__form__document' key={item.name}>
              <FormEditWrapper isFormEdit={isFormEdit} keyVal={item.name}>
                <Attachment
                  {...item}
                  handleChange={(event) => handleChange(event, item)}
                  handleDeleteFile={(file) => handleDeleteFile(file, item)}
                  disabled={prodBasicInfoData?.productId ? false : true}
                />
              </FormEditWrapper>
            </div>
          );

        case DynamicFieldConstants.TEXT_AND_FILE:
          return (
            <div className='home-wrapper__form__document' key={item.name}>
              <div className='itemscondition-childcontainer'>
                <FormEditWrapper isFormEdit={isFormEdit} keyVal={item.name}>
                  <RichTextEditorInput
                    handleChange={(event) => handleChange(event, item)}
                    disabled={
                      item?.key !== 'prodBasicInfo' && !prodBasicInfoData?.productId
                        ? true
                        : false
                    }
                    {...item}
                  />
                  {item.attributes?.length > 0 && (
                    <>
                      <div className='selection-divider'>
                        <p className='divider-heading'> OR </p>
                      </div>
                      <UserManual
                        handleChange={(event) => {
                          handleChange(event, item);
                          handleTextAndFileFieldValidation(item.name, item);
                        }}
                        handleDeleteFile={(file) => {
                          handleDeleteFile(file, item);
                          handleTextAndFileFieldValidation(item.name, item);
                        }}
                        prodId={prodBasicInfoData?.productId}
                        customClass='pdu__container_text_and_file'
                        handlePreviewElement={handleViewDocument}
                        disabled={
                          !prodBasicInfoData?.productId ||
                          item?.attributes[0].validation?.maxFiles ===
                            item?.attributes[0].value?.length
                            ? true
                            : false
                        }
                        {...item.attributes[0]}
                      />
                    </>
                  )}
                </FormEditWrapper>
              </div>
            </div>
          );

        case DynamicFieldConstants.FILE:
          if (item.name === 'termsAndConditionsAttachment') return;
          return (
            <div className='home-wrapper__form__document' key={item.name}>
              <FormEditWrapper isFormEdit={isFormEdit} keyVal={item.name}>
                <UserManual
                  handleChange={
                    item.key === 'prodBasicInfo' && !prodBasicInfoData?.productId
                      ? (data) => handleDPNFileSelect(data, item)
                      : (event) => handleChange(event, item)
                  }
                  handleDeleteFile={
                    item.key === 'prodBasicInfo' && !prodBasicInfoData?.productId
                      ? () => handleUpdateDPNDocument(null)
                      : (file) => handleDeleteFile(file, item)
                  }
                  prodId={prodBasicInfoData?.productId}
                  handlePreviewElement={handleViewDocument}
                  value={item.key === 'prodBasicInfo' && dpnDocument}
                  disabled={
                    (item.key === 'prodBasicInfo' && Boolean(dpnDocument)) ||
                    (item.key !== 'prodBasicInfo' &&
                      !prodBasicInfoData?.productId) ||
                    item?.validation?.maxFiles === item?.value?.length
                  }
                  {...item}
                />
              </FormEditWrapper>
            </div>
          );

        case DynamicFieldConstants.CHECKBOX:
          return (
            <div className='home-wrapper__form__document' key={item.name}>
              <FormEditWrapper isFormEdit={isFormEdit} keyVal={item.name}>
                <h5>{item.displayName}</h5>
                <div className='home-wrapper__form__tenant'>
                  {item?.options?.map((option) => (
                    <CategoryCard
                      key={option.key}
                      value={option.key}
                      displayName={option.value}
                      id={option.key}
                      name={item.name}
                      checkValue={'mos' === option.key}
                      changeHandler={(event) => handleChange(event, item)}
                    />
                  ))}
                </div>
              </FormEditWrapper>
            </div>
          );

        case DynamicFieldConstants.DATE:
          return (
            <div className='home-wrapper__form__input' key={item.name}>
              <FormEditWrapper isFormEdit={isFormEdit} keyVal={item.name}>
                <div className='home-wrapper__form__date a-text-field'>
                  <DatePicker
                    name={item.displayName}
                    value={item.value}
                    validation={item.validation}
                    changeHandler={(event) => {
                      handleChange(event, item);
                    }}
                  />
                </div>
              </FormEditWrapper>
            </div>
          );
        default:
          return null;
      }
    }
  };
  const isCheckLastStep = (data) => {
    return (
      activeCategory === onBoardingFormData?.steps?.length - 1 &&
      (data?.sections?.length === 0 ||
        (data?.sections?.length > 0 &&
          activeGrouping === data?.sections?.length - 1))
    );
  };

  const handleGetTextValue = (textValue) => {
    const values = [];
    textValue?.blocks?.forEach((item) => {
      if (item.text !== '') {
        values.push(item.text);
      }
    });
    return values.join('');
  };

  const isCheckAllKeysValuePresent = (data) => {
    const filteredData = data.filter((item) =>
      Object.values(item).some((value) => value?.length > 0)
    );
    for (const item of filteredData) {
      if (
        !Object.entries(item)
          .filter((item) => item[0] !== 'attachment' && item[0] !== 'caseStudy')
          .every((value) => value[1])
      ) {
        return true;
      }
    }
    return false;
  };

  const getIsRequired = (element, required) => {
    if (
      IS_MKOSINT_TENANT &&
      element.attributeType === DynamicFieldConstants.TEXT_AND_FILE
    ) {
      return required?.errorMessage;
    } else {
      return required?.isRequired;
    }
  };

  const handleDisbleNextBtn = (data, groupData, saveButton) => {
    let fields = [];
    let isPreventNextPage;
    if (
      !saveButton &&
      ((data.name !== 'prodBasicInfo' && !prodBasicInfoData.productId) ||
        (isCheckLastStep(groupData) && !IS_MKOSINT_TENANT && !checkboxChecked))
    ) {
      isPreventNextPage = true;
      return isPreventNextPage;
    }

    if (
      activeCategory === onBoardingFormData.steps.length - 1 &&
      groupData?.sections?.length === 0 &&
      handleCheckValuesChanged() &&
      !isShowPricingPlanNotification(prodBasicInfoData?.status?.statusId, apiPlans)
    ) {
      isPreventNextPage = true;
      return isPreventNextPage;
    }

    if (groupData?.sections?.length > 0) {
      if (
        activeCategory === onBoardingFormData.steps.length - 1 &&
        activeGrouping === groupData.sections.length - 1 &&
        handleCheckValuesChanged() &&
        !isShowPricingPlanNotification(prodBasicInfoData?.status?.statusId, apiPlans)
      ) {
        isPreventNextPage = true;
        return isPreventNextPage;
      }
    }

    if (data && data?.groups?.length > 0) {
      const values = [];
      data.groups.forEach((element) => {
        fields = fields.concat(element.attributes);
        const obj = {};
        //Making title and value pair mandatory for a group, if any one value is present.
        element.attributes.forEach((element) => {
          if (
            element.attributeType === DynamicFieldConstants.UPLOAD_IMAGE ||
            element.attributeType === DynamicFieldConstants.TEXT ||
            element.attributeType === DynamicFieldConstants.TEXT_AREA
          ) {
            obj[element.name] = element.value;
          }
        });
        values.push(obj);
        if (isCheckAllKeysValuePresent(values)) {
          isPreventNextPage = true;
          return isPreventNextPage;
        }
      });
    } else {
      fields = data?.attributes;
    }

    for (const element of fields) {
      const rules = element?.validation;
      const required = rules?.rule?.required;
      if (element?.name === 'addPricingPlan' && apiPlans?.length === 0) {
        isPreventNextPage = true;
        break;
      }

      let isValidValue;
      if (
        element.attributeType === DynamicFieldConstants.RICHTEXTEDITOR &&
        isValidJSONString(element?.value)
      ) {
        isValidValue = handleGetTextValue(JSON.parse(element?.value))?.length === 0;
      } else if (element.attributeType === DynamicFieldConstants.TEXT_AND_FILE) {
        if (
          (!element.value ||
            (element.value &&
              handleGetTextValue(JSON.parse(element.value))?.length === 0)) &&
          (!element.attributes[0]?.value || element.attributes[0].value.length === 0)
        ) {
          isValidValue = true;
        }
      } else {
        isValidValue = element?.value?.length === 0 || !element?.value;
      }
      if (
        (getIsRequired(element, required) && isValidValue) ||
        (element?.error?.length && element?.error?.length !== 0)
      ) {
        isPreventNextPage = true;
        break;
      }
    }

    if (onBoardingFormData.isQuotation === true && isCheckLastStep(groupData)) {
      return false;
    }

    if (isCheckLastStep(groupData) && apiPlans?.length === 0) {
      isPreventNextPage = true;
    }

    // Add quotation logic to enable nextButton
    if (
      data?.name === FieldConstants.pricingPlan &&
      onBoardingFormData.isQuotation === true
    ) {
      // Next Button is enabled if category is quotation
      return false;
    }

    // Check the below logic only if isPreventNextPage not equals true
    if (
      prodBasicInfoData?.status?.statusId !== idConstants.drafted.statusId &&
      prodBasicInfoData?.productId &&
      isPreventNextPage !== true
    ) {
      if (termsAndConditionFile?.length > 0 && termsAndConditionFile[0].type) {
        return false;
      }

      // Next Button is enabled if pricing plan only added
      if (
        isShowPricingPlanNotification(
          prodBasicInfoData?.status?.statusId,
          apiPlans
        ) &&
        handleCheckValuesChanged()
      ) {
        return false;
      }
    }

    return isPreventNextPage;
  };

  const handleGroupData = (groupDataCpy) => {
    const onBoardingFormDataCpy = _.cloneDeep(onBoardingFormData);
    const onboardingDataValueCpy =
      onBoardingFormDataOnlyValue && _.cloneDeep(onBoardingFormDataOnlyValue);
    onBoardingFormDataCpy['steps'][activeCategory]['sections'][activeGrouping][
      'groups'
    ] = groupDataCpy;
    dispatch(saveFormJsonData(onBoardingFormDataCpy));
    if (onboardingDataValueCpy && onboardingDataValueCpy.steps) {
      const groupDataOnlyValue = groupDataCpy.map((groupObj) => {
        const attributes = groupObj.attributes.map((attributeObj) => {
          return {
            name: attributeObj.name,
            xpath: attributeObj.xpath,
            value: attributeObj.value ? attributeObj.value : undefined
          };
        });
        return {
          name: groupObj.name,
          xpath: groupObj.xpath,
          attributes
        };
      });
      onboardingDataValueCpy['steps'][activeCategory]['sections'][activeGrouping][
        'groups'
      ] = groupDataOnlyValue;
      dispatch(saveFormJsonDataOnlyValue(onboardingDataValueCpy));
    }
  };

  const handleDeleteAttributePayload = (item, itemToRemove) => {
    const data = handleParseXpath(item.xpath, [itemToRemove]);
    const categoryId = prodBasicInfoData?.productCategory?.categoryId;
    const reqObj = {};
    const prodBasicInfo = {};
    const productCategory = {};
    productCategory.categoryId = categoryId;
    prodBasicInfo.productCategory = { ...productCategory };
    reqObj.productDetailedInfo = { ...data.productDetailedInfo };
    reqObj.prodBasicInfo = { ...prodBasicInfo };
    return reqObj;
  };

  const handleRemoveOption = (event, item) => {
    if (
      prodBasicInfoData?.productId &&
      event?.removedItem &&
      item.key !== FieldConstants.prodBasicInfo
    ) {
      const result = item.xpath
        .split('/')
        .map((element) => element.trim().toString())
        .filter((element) => element !== '');
      let assignField = getNestedVal(
        { productDetailedInfo: { ...prodDetailedInfo } },
        result
      );
      if (assignField?.id) {
        assignField = [assignField];
      }
      const isFindVal =
        assignField &&
        assignField.some((item) => item.id === event?.removedItem?.id);
      if (isFindVal) {
        const requestObj = handleDeleteAttributePayload(item, event.removedItem);
        dispatch(deleteProductGroup(requestObj, prodBasicInfoData?.productId)).then(
          (res) => {
            if (res) {
              handleChange(event, item);
            }
          }
        );
      } else {
        handleChange(event, item);
      }
    } else {
      handleChange(event, item);
    }
  };

  const isImageUploaded = (attributes) => {
    const element = attributes.find(
      (item) => item.attributeType === DynamicFieldConstants.UPLOAD_IMAGE
    );
    if (element?.value?.length > 0) {
      return element.value;
    }
  };

  const handleDeleteGroup = async (item, updatedData) => {
    const groupId = item.attributes[0]._id;
    if (groupId) {
      const value = isImageUploaded(item.attributes);
      if (value?.length > 0) {
        await dispatch(
          deleteFile(value[0], prodBasicInfoData.productCategory.categoryId)
        );
      }
      const data = handleParseXpath(item.xpath, [{ _id: groupId }]);
      const categoryId = prodBasicInfoData.productCategory.categoryId;
      const reqObj = {};
      const prodBasicInfo = {};
      const productCategory = {};
      productCategory.categoryId = categoryId;
      prodBasicInfo.productCategory = { ...productCategory };
      reqObj.productDetailedInfo = { ...data.productDetailedInfo };
      reqObj.prodBasicInfo = { ...prodBasicInfo };
      dispatch(deleteProductGroup(reqObj, prodBasicInfoData?.productId)).then(
        (res) => {
          if (res) {
            handleGroupData(updatedData);
          }
        }
      );
    } else {
      handleGroupData(updatedData);
    }
  };

  const handlePricingPlanModal = (item) => {
    setPlanData(item);
    setShowPricingPlanModal((prevState) => !prevState);
    setEditPlan(null);
  };

  const handleGetNextStepLabel = (data) => {
    let nextStepLabel = i18next.t('DYNAMIC_FORM.SHARED.BUTTON.NEXT_STEP');
    if (
      activeCategory === onBoardingFormData.steps.length - 1 &&
      data?.sections?.length === 0
    ) {
      if (
        isShowPricingPlanNotification(
          prodBasicInfoData?.status?.statusId,
          apiPlans
        ) &&
        handleCheckValuesChanged()
      ) {
        nextStepLabel = i18next.t('DYNAMIC_FORM.SHARED.BUTTON.SUBMIT_CHANGES');
      } else {
        nextStepLabel = IS_MKOSINT_TENANT
          ? i18next.t('DYNAMIC_FORM.LABEL.PUBLISH')
          : i18next.t('DYNAMIC_FORM.SHARED.BUTTON.SUBMIT');
        if (!IS_MKOSINT_TENANT && !checkboxVisible) {
          setCheckboxVisible(true);
        }
      }
    } else {
      if (data?.sections?.length > 0) {
        if (
          activeCategory === onBoardingFormData.steps.length - 1 &&
          activeGrouping === data.sections.length - 1
        ) {
          if (
            isShowPricingPlanNotification(
              prodBasicInfoData?.status?.statusId,
              apiPlans
            ) &&
            handleCheckValuesChanged()
          ) {
            nextStepLabel = i18next.t('DYNAMIC_FORM.SHARED.BUTTON.SUBMIT_CHANGES');
          } else {
            nextStepLabel = IS_MKOSINT_TENANT
              ? i18next.t('DYNAMIC_FORM.LABEL.PUBLISH')
              : i18next.t('DYNAMIC_FORM.SHARED.BUTTON.SUBMIT');
          }
          if (!IS_MKOSINT_TENANT && !checkboxVisible) {
            setCheckboxVisible(true);
          }
        } else {
          if (activeGrouping < data.sections.length - 1) {
            nextStepLabel = i18next.t('DYNAMIC_FORM.SHARED.BUTTON.NEXT');
          }
        }
      }
    }

    if (checkboxVisible && !isCheckLastStep(data)) {
      setCheckboxVisible(false);
    }

    if (
      prodBasicInfoData?.status?.statusId !== idConstants.drafted.statusId &&
      prodBasicInfoData?.productId &&
      (!handleCheckValuesChanged() ||
        (termsAndConditionFile?.length > 0 && termsAndConditionFile[0].type)) &&
      [
        i18next.t('DYNAMIC_FORM.SHARED.BUTTON.NEXT_STEP'),
        i18next.t('DYNAMIC_FORM.SHARED.BUTTON.NEXT')
      ].includes(nextStepLabel)
    ) {
      nextStepLabel = i18next.t('DYNAMIC_FORM.SHARED.BUTTON.SAVE_AND_NEXT');
    }

    return nextStepLabel;
  };

  const handleIndicatorClick = (activeItem) => {
    setActiveGrouping(activeItem);
  };

  const handleClose = () => {
    if (prodBasicInfoData?.productId) {
      handleCloseDialogBox();
    } else {
      history.push(locationState.redirectPathname);
    }
  };

  const getProductName = () => {
    if (onBoardingFormData.steps) {
      const findValIndex = onBoardingFormData?.steps[0]['attributes'].findIndex(
        (item) => item.name === FieldConstants.productName
      );
      const productName =
        onBoardingFormData.steps[0]['attributes'][findValIndex]?.value;
      return productName;
    }
  };

  const isShowAutoSaveText = () => {
    if (
      (locationState?.isEdit && !prodBasicInfoData?.status?.statusId) ||
      (dynamicData?.name === FieldConstants.prodBasicInfo &&
        handleDisbleNextBtn(dynamicData))
    ) {
      return false;
    }
    return (
      prodBasicInfoData?.status?.statusId === idConstants.drafted.statusId ||
      !prodBasicInfoData?.status
    );
  };

  const stepperTitle = onBoardingFormData?.parentCategory
    ? onBoardingFormData?.parentCategory
    : '';

  const breadcrumbTitle = onBoardingFormData?.category
    ? onBoardingFormData?.category
    : '';

  const handleConfirmationModal = (details) => {
    setConfirmationModal(details);
  };
  const excludedStatuses = [
    idConstants.drafted.statusId,
    idConstants.submitted.statusId
  ];
  const showBannerOnEdit =
    prodBasicInfoData?.status?.statusId &&
    !excludedStatuses.includes(prodBasicInfoData?.status?.statusId);

  let homeWrapperClass = 'home-wrapper';
  if (isShowNotification && showBannerOnEdit) {
    homeWrapperClass += ' home-wrapper-final';
  } else if (isShowNotification) {
    homeWrapperClass += ' home-wrapper-notification';
  } else if (showBannerOnEdit) {
    homeWrapperClass += ' home-wrapper-badge';
  }

  const autoSaveText = getAutoSaveInfo(TimeoutConstant.autoSave)?.autoSaveText;

  return (
    <div className='home__container'>
      <IdleTimer
        ref={idleTimer}
        timeout={TimeoutConstant.autoSave}
        onIdle={handleIdle}
      />

      {showPreviewModal && previewData && (
        <PreviewModal
          mediaUrl={previewData.blobFileUrl}
          isVideo={handleCheckUploadType(previewData.documentName)}
          handlePreviewModal={handlePreviewModal}
        />
      )}

      {showPlanModal && (
        <PricingPlanDialog
          planPreviewData={planPreviewData}
          handleCancel={() => handlePlanModal()}
          rateLimitTypes={onBoardingFormData?.rateLimitTypes}
        />
      )}

      {confirmationModal?.isShow && (
        <DialogBox
          title={confirmationModal.title}
          subTitle={confirmationModal.subTitle}
          isModal={true}
          handleConfirm={confirmationModal.action}
          handleCancel={() => handleConfirmationModal({})}
        />
      )}

      {showDialogBoxModal && (
        <DialogBox
          title={dialogText.cancelConfirmation.modalHeading}
          subTitle={dialogText.cancelConfirmation.confirmModalText}
          isModal={true}
          handleConfirm={() => {
            handleCloseDialogBox();
            history.push(locationState.redirectPathname);
          }}
          handleCancel={handleCloseDialogBox}
        />
      )}
      {showSuccessBoxModal && (
        <DialogBox
          primaryLabel={i18next.t('DYNAMIC_FORM.LABEL.OKAY')}
          {...initialSuccessBoxModalValue}
          isModal={true}
          isSuccess={true}
          isSecondaryBtn={false}
          handleConfirm={() => {
            handleSuccessDialogBox();
            history.push(locationState.redirectPathname);
          }}
          handleCancel={() => {
            handleSuccessDialogBox();
            history.push(locationState.redirectPathname);
          }}
          cssName='home__container__success-modal'
        />
      )}
      <div className='home__breadcrumb'>
        <Breadcrumb
          items={[
            {
              title: breadcrumbTitle,
              to: '/',
              from: '/dashboard/my-products/'
            },
            {
              title: getProductName()
            }
          ]}
          className='bread-crumb__container__arrow'
        />
      </div>

      {showBannerOnEdit && (
        <div className='published-content-banner'>
          <img
            className='published-content-banner--icon'
            src={images.alertErrorBlack}
          />
          {i18next.t('DIALOG.EDIT_INFO.BANNER_CONTENT')}
          <IconButton
            iconCss='published-content-banner--tooltip-icon'
            iconTitle={i18next.t('DIALOG.EDIT_INFO.BANNER_INFO_CONTENT')}
            src={images.infoIcon}
            isIcon={false}
          />
        </div>
      )}

      {isShowNotification && (
        <PricingStatusNotification
          plans={apiPlans}
          isShowSubmitNotification={isShowSubmitNotification}
        />
      )}

      <div className={homeWrapperClass}>
        <VerticleStepper
          title={stepperTitle}
          description={onBoardingFormData?.category}
          stepperOption={onBoardingFormData?.steps}
          activeCategory={activeCategory}
          setActiveCategory={handleStepCategory}
          data={onBoardingFormData?.steps}
        />
        <main className='home-wrapper__main-container'>
          <div className='home-wrapper__form'>
            {onBoardingFormData &&
              onBoardingFormData?.steps?.map(
                (data, index) =>
                  activeCategory === index && (
                    <div className='home-wrapper__form__data' key={data.id}>
                      <div className='home-wrapper__form__container'>
                        {data.sections && data?.sections?.length > 0 ? (
                          <>
                            <StepIndicator
                              data={data?.sections}
                              active={activeGrouping}
                              size={'-small'}
                              customClass={'home-wrapper__form__wrapper'}
                              handleClick={handleIndicatorClick}
                              isFormEdit={isFormEdit}
                            >
                              {data?.sections?.map(
                                (item, index2) =>
                                  activeGrouping === index2 && (
                                    <>
                                      {item?.attributes?.map((itemField) =>
                                        handleGetFieldInput({
                                          ...itemField,
                                          isGrouping: true
                                        })
                                      )}
                                      {item?.groups && item?.groups?.length > 0 && (
                                        <GroupingCard
                                          group={item.groups}
                                          handleGetFieldInput={handleGetFieldInput}
                                          handleGroupData={handleGroupData}
                                          handleDeleteGroup={handleDeleteGroup}
                                        />
                                      )}

                                      {index2 === 0 &&
                                        data.name === 'productDetail' && (
                                          <div className='visibility-div'>
                                            <VisibilitySettings
                                              productId={
                                                prodBasicInfoData?.productId
                                              }
                                              isVisibleData={isVisibleData}
                                              handleIsVisible={handleIsVisible}
                                              disabled={false}
                                            />
                                          </div>
                                        )}
                                      {isShowAutoSaveText() && (
                                        <div className='-size-m d-lg-none navigation-buttons__auto-save-text home-wrapper__form__document'>
                                          {autoSaveText}
                                        </div>
                                      )}
                                    </>
                                  )
                              )}
                            </StepIndicator>
                          </>
                        ) : (
                          <div className='home-wrapper__form__wrapper'>
                            {data.attributes &&
                              data.attributes.map((fields) =>
                                handleGetFieldInput({
                                  ...fields,
                                  key: data.name,
                                  planCardData: apiPlans
                                },
                                data.attributes)
                              )}
                            {data.name === 'productDetail' && (
                              <div className='visibility-div'>
                                <VisibilitySettings
                                  productId={prodBasicInfoData?.productId}
                                  isVisibleData={isVisibleData}
                                  handleIsVisible={handleIsVisible}
                                  disabled={false}
                                />
                              </div>
                            )}
                            {isShowAutoSaveText() && (
                              <div className='-size-m d-lg-none navigation-buttons__auto-save-text home-wrapper__form__document'>
                                {autoSaveText}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <div className='home-wrapper__form__actions'>
                        {checkboxVisible && (
                          <div className='checkbox-confirmation'>
                            <CheckBox
                              displayName={i18next.t(
                                'DYNAMIC_FORM.SHARED.SUCCESS_MESSAGE.I_CONFIRM_THAT_ALL_THE_PROVIDED_INFORMATION_IS_CORRECT_TO_BEST_OF_MY_KNOWLEDGE'
                              )}
                              handleChange={handleCheckboxChange}
                              id='confirm'
                              checked={checkboxChecked}
                            />
                          </div>
                        )}
                        <NavigationButtons
                          isShowCancelBtn={true}
                          isShowSaveBtn={true}
                          handlePrevious={() =>
                            handlePreviousCategory(
                              data?.sections?.length > 0
                                ? data['sections'][activeGrouping]
                                : data,
                              data?.sections?.length > 0,
                              data
                            )
                          }
                          handleClose={handleClose}
                          nextStepDisabled={handleDisbleNextBtn(
                            data?.sections?.length > 0
                              ? data['sections'][activeGrouping]
                              : data,
                            data
                          )}
                          nextStepLabel={handleGetNextStepLabel(data)}
                          prevStepLabel={
                            data?.sections?.length > 0
                              ? i18next.t('DYNAMIC_FORM.SHARED.BUTTON.BACK')
                              : i18next.t('DYNAMIC_FORM.SHARED.BUTTON.PREVIOUS_STEP')
                          }
                          handleNext={() => {
                            handleNextCategory(
                              data?.sections?.length > 0
                                ? data['sections'][activeGrouping]
                                : data,
                              data?.sections?.length,
                              data
                            );
                          }}
                          isShowPrevStepBtn={activeCategory > 0}
                          /* Commenting the preview button component as we are not usnig this flow. */
                          // isShowPreviewbtn={
                          //   activeCategory ===
                          //     onBoardingFormData.steps.length - 1 &&
                          //   activeGrouping === data.sections.length - 1
                          // }
                          // handlePreview={() => handleProductPreview()}
                          isAutosaved={isShowAutoSaveText()}
                          isPreviewDisabled={
                            prodBasicInfoData?.productId ? false : true
                          }
                          navigationMessage={autoSaveText}
                          enableSaveButton={handleDisbleNextBtn(
                            data?.sections?.length > 0
                              ? data['sections'][activeGrouping]
                              : data,
                            data,
                            true
                          )}
                          saveDraft={handleIdle}
                        />
                      </div>
                    </div>
                  )
              )}
          </div>
        </main>
      </div>

      {pricingPlanModal && (
        <PricingPlanScreen
          handleGetFieldInput={handleGetFieldInput}
          setShowPricingPlanModal={setShowPricingPlanModal}
          pricingPlanData={planData}
          prodId={prodBasicInfoData?.productId}
          editPlan={editPlan}
          {...props}
        />
      )}

      {infoModal.openInfoModal && (
        <InfoModal
          isTitleIconRequired={infoModal?.isTitleIconRequired}
          modalContent={infoModal.modalDescription}
          heading={infoModal.modalHeading}
          okBtnAction={closeInfoPopup}
          onClose={(event) => {
            closeInfoPopup();
            if (
              event?.currentTarget?.innerText !==
              i18next.t('DYNAMIC_FORM.LABEL.EDIT_ANYWAY')
            ) {
              !infoModal?.takeNoAction &&
                history.push(locationState.redirectPathname);
            }
          }}
          handleSecondaryBtnClick={() => {
            closeInfoPopup();
            !infoModal?.takeNoAction && history.push(locationState.redirectPathname);
          }}
          primaryLabel={
            infoModal?.primaryLabel || i18next.t('DYNAMIC_FORM.LABEL.EDIT_ANYWAY')
          }
          secondaryLabel={infoModal?.secondaryLabel}
        />
      )}
    </div>
  );
};

FormContainer.propTypes = {
  onBoardingFormData: PropTypes.object,
  activeCategory: PropTypes.number,
  handleStepCategory: PropTypes.func,
  handleChange: PropTypes.func,
  blurValue: PropTypes.func,
  apiPlans: PropTypes.array,
  handleNextCategory: PropTypes.func,
  activeGrouping: PropTypes.number,
  setActiveGrouping: PropTypes.func,
  handlePreviousCategory: PropTypes.func,
  isFormEdit: PropTypes.bool
};

FormContainer.defaultProps = {
  onBoardingFormData: {},
  activeCategory: 0,
  handleStepCategory: () => null,
  handleChange: () => null,
  blurValue: () => null,
  apiPlans: [],
  handleNextCategory: () => null,
  activeGrouping: 0,
  setActiveGrouping: () => null,
  handlePreviousCategory: () => null,
  isFormEdit: false
};

export default FormContainer;
